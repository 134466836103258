import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import {
  Paper,
  Box,
  IconButton,
  Grid,
  Backdrop,
  CircularProgress,
  Card,
  CardHeader,
  Snackbar,
  Fab,
  Modal,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  List,
  Tooltip,
  ListItem,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  Button,
  ButtonGroup,
  FormControlLabel,
} from "@material-ui/core";
import { Textofield2, checkBoxStandar, selectStandar, selecto, tablaIconos } from "../Fomulario/formulario";
import MaterialTable, { MTableBody } from "material-table";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import ModalTutoriales, { traerTutoriales } from "../Tutoriales/tutoriales";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
//import ModidyIcon from '@material-ui/icons/Modify';
import VisibilityIcon from "@material-ui/icons/Visibility";

import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

import SearchIcon from "@material-ui/icons/Search";

import BotonesTriger from "../Botones/botonTrige";
import Cabecera from "../Cabecera/index_365";
import "./styles.css";

import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import green from "@material-ui/core/colors/green";

import firebase2 from "firebase";
import moment from "moment";
import Copyright from "../Privacidad/copyright";
import { FormatColorReset } from "@material-ui/icons";

const tableIcons = tablaIconos;

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
      claro: green[200],
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#e81446",
    },
  },
});

let i = 0;
let menuItems30 = [];
for (i = 1; i < 31; i++) {
  menuItems30.push(<MenuItem value={i}>{i} día</MenuItem>);
}
let menuItems30_60_90 = menuItems30;
menuItems30_60_90.push(<MenuItem value={60}>{60} día</MenuItem>);
menuItems30_60_90.push(<MenuItem value={90}>{90} día</MenuItem>);

let menuItems10 = [];
menuItems10[0] = <MenuItem value={0}>Ningún día antes</MenuItem>;
menuItems10[1] = <MenuItem value={1}>{1} día antes</MenuItem>;

for (i = 2; i < 10; i++) {
  menuItems10.push(<MenuItem value={i}>{i} días antes</MenuItem>);
}

let menuItems20 = [<MenuItem value={1}>1 persona</MenuItem>];
for (i = 1; i < 20; i++) {
  menuItems20.push(<MenuItem value={i + 1}>{i + 1} persona/s</MenuItem>);
}

let menuItems6 = [<MenuItem value={0}>No aplica</MenuItem>];
for (i = 1; i < 7; i++) {
  menuItems6.push(<MenuItem value={i}>{i} reservas</MenuItem>);
}

let menuItems6slot = [<MenuItem value={1}>1 slot</MenuItem>];
for (i = 1; i < 6; i++) {
  menuItems6slot.push(<MenuItem value={i + 1}>Hasta {i + 1} slots</MenuItem>);
}

let menuItems6dias = [<MenuItem value={1}>1</MenuItem>];
for (i = 2; i < 7; i++) {
  menuItems6dias.push(<MenuItem value={i}>{i}</MenuItem>);
}

let menuItems24ap = [<MenuItem value={0}>0</MenuItem>];
for (i = 1; i < 24; i++) {
  menuItems24ap.push(<MenuItem value={i}> {i} </MenuItem>);
}

let menuItems25ap = [<MenuItem value={1}>1</MenuItem>];
for (i = 2; i < 25; i++) {
  menuItems25ap.push(<MenuItem value={i}> {i} </MenuItem>);
}
menuItems25ap.push(<MenuItem value={48}> {48} </MenuItem>);
menuItems25ap.push(<MenuItem value={72}> {72} </MenuItem>);
menuItems25ap.push(<MenuItem value={96}> {96} </MenuItem>);
let menuItems24 = [
  <MenuItem value="00:00">00:00</MenuItem>,
  <MenuItem value="00:30">00:30</MenuItem>,
  <MenuItem value="01:00">01:00</MenuItem>,
  <MenuItem value="01:30">01:30</MenuItem>,
  <MenuItem value="02:00">02:00</MenuItem>,
  <MenuItem value="02:30">02:30</MenuItem>,
  <MenuItem value="03:00">03:00</MenuItem>,
  <MenuItem value="03:30">03:30</MenuItem>,
  <MenuItem value="04:00">04:00</MenuItem>,
  <MenuItem value="04:30">04:30</MenuItem>,
  <MenuItem value="05:00">05:00</MenuItem>,
  <MenuItem value="05:30">05:30</MenuItem>,
  <MenuItem value="06:00">06:00</MenuItem>,
  <MenuItem value="06:30">06:30</MenuItem>,
  <MenuItem value="07:00">07:00</MenuItem>,
  <MenuItem value="07:30">07:30</MenuItem>,
  <MenuItem value="08:00">08:00</MenuItem>,
  <MenuItem value="08:30">08:30</MenuItem>,
  <MenuItem value="09:00">09:00</MenuItem>,
  <MenuItem value="09:30">09:30</MenuItem>,
  <MenuItem value="10:00">10:00</MenuItem>,
  <MenuItem value="10:30">10:30</MenuItem>,
  <MenuItem value="11:00">11:00</MenuItem>,
  <MenuItem value="11:30">11:30</MenuItem>,
  <MenuItem value="12:00">12:00</MenuItem>,
  <MenuItem value="12:30">12:30</MenuItem>,
  <MenuItem value="13:00">13:00</MenuItem>,
  <MenuItem value="13:30">13:30</MenuItem>,
  <MenuItem value="14:00">14:00</MenuItem>,
  <MenuItem value="14:30">14:30</MenuItem>,
  <MenuItem value="15:00">15:00</MenuItem>,
  <MenuItem value="15:30">15:30</MenuItem>,
  <MenuItem value="16:00">16:00</MenuItem>,
  <MenuItem value="16:30">16:30</MenuItem>,
  <MenuItem value="17:00">17:00</MenuItem>,
  <MenuItem value="17:30">17:30</MenuItem>,
  <MenuItem value="18:00">18:00</MenuItem>,
  <MenuItem value="18:30">18:30</MenuItem>,
  <MenuItem value="19:00">19:00</MenuItem>,
  <MenuItem value="19:30">19:30</MenuItem>,
  <MenuItem value="20:00">20:00</MenuItem>,
  <MenuItem value="20:30">20:30</MenuItem>,
  <MenuItem value="21:00">21:00</MenuItem>,
  <MenuItem value="21:30">21:30</MenuItem>,
  <MenuItem value="22:00">22:00</MenuItem>,
  <MenuItem value="22:30">22:30</MenuItem>,
  <MenuItem value="23:00">23:00</MenuItem>,
  <MenuItem value="23:30">23:30</MenuItem>,
  <MenuItem value="24:00">24:00</MenuItem>,
];

const menuZona = [
  <MenuItem key={1} value={1}>
    Piscina
  </MenuItem>,
  <MenuItem key={2} value={2}>
    Pista Padel
  </MenuItem>,
  <MenuItem key={3} value={3}>
    Pista Tenis
  </MenuItem>,
  <MenuItem key={4} value={4}>
    Sala Reuniones
  </MenuItem>,
  <MenuItem key={5} value={5}>
    Gimnasio
  </MenuItem>,
  <MenuItem key={6} value={6}>
    Otros
  </MenuItem>,
  <MenuItem key={7} value={7}>
    Barbacoa
  </MenuItem>,
  <MenuItem key={8} value={8}>
    Jardín/Zona juegos
  </MenuItem>,
  <MenuItem key={9} value={9}>
    Frontón
  </MenuItem>,
  <MenuItem key={10} value={10}>
    Sala infantil
  </MenuItem>,
  <MenuItem key={11} value={11}>
    Solarium
  </MenuItem>,
  <MenuItem key={12} value={12}>
    Spa
  </MenuItem>,
  <MenuItem key={13} value={13}>
    Piscina II
  </MenuItem>,
  <MenuItem key={14} value={14}>
    Bus
  </MenuItem>,
  <MenuItem key={15} value={15}>
    Aseos
  </MenuItem>,
];

const limitaSiReserva = [
  <MenuItem value={1}>SIN LIMITACIONES</MenuItem>,
  <MenuItem value={2}>Sólo a partir de la hora de aplicación, para el día siguiente</MenuItem>,
  <MenuItem value={3}>Sólo el mismo día de Reserva Activa hasta consumir las del día</MenuItem>,
  <MenuItem value={4}>Sólo puedes reservar con un minimmo de horas de diferencia</MenuItem>,
  <MenuItem value={5}>Liberar el siguiente slot después de unas horas</MenuItem>,
];

const menuIntervalos = [
  <MenuItem value={1}>Por semana</MenuItem>,
  <MenuItem value={2}>Por mes</MenuItem>,
  <MenuItem value={3}>Por año</MenuItem>,
  <MenuItem value={4}>Por temporada</MenuItem>,
  <MenuItem value={5}>Por días</MenuItem>,
];

class Zonas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AperturaPuerta: {},
      DespuesMinutos: 0,
      DesdeMinutos: 0,
      AperturaPuertaActivar: false,
      umbralInvitadosSlot: 0,
      numeroConvivientes: 0,
      listaZonasRecuperadasFiltrada: [],
      controlPersonasAsistentes: false,
      listaZonasRecuperadas: [],
      añoActual: [],
      años: [],
      añosAccesos: [],
      menuComunidades: [],
      abrir_zonas: false,
      usuarios: [],
      lista_abonos: [],
      lista_abonos_luz: [],
      lista_pagos_abonos: [],
      lista_pagos_abonos_luz: [],
      abrirTablaAbonos: false,
      abrirTablaAbonosLuz: false,
      abrirTablaTipoCobroAbono: false,
      abrirTablaTipoCobroAbonoLuz: false,
      abrir_cambios: false,
      NoconsumirStockDia: false,
      margenSinConsumir: "24:00",
      uid: "",
      NoPermitirSiReservaActiva: false,
      mostrarPisoPortalPuerta: false,
      mostrarAyuda: false,
      LimitacionHora: 0,
      Limitaciondias: 0,
      inicioStock: "03/03/2020",
      finStock: "03/03/2020",
      horarioFindes: false,
      controlStock: false,
      controlAbonos: false,
      pagoAbonoApp: false,
      controlAbonosLuz: false,
      stockLimite: 1,
      tipo_zona: "",
      limitaciones: 0,
      tipoControlStock: 1,
      calendario: false,
      PermitirReservarHora: "24:00",
      añadir: true,
      apertura: "07:00",
      tamaño_slot: 1,
      cierre: "18:00",
      numreservas: 1,
      numpersonas: 1,
      domingos: false,
      sabados: false,
      festivos: false,
      diasFestivos: [],
      horizonte: 0,
      diasAntesSinConsumir: 0,
      incis: [],
      identIncial: "",
      comus: [],
      zonas: [],
      open: false,
      openRegistroAcceso: false,
      registrosAccesos: [],
      registrosAccesosFiltrados: [],
      comunidad: "",
      tipo: "guardar",
      zona: "",
      mostrarTabla: false,
      selectedIndex: "",
      email: "",
      zonaBorrar: "",
      password: "",
      error: null,
      error2: null,
      horariosLuzFin: "",
      horariosLuzInicio: "",
      modificar: true,
      listaComunidades: [],
      Cierre_ant: "",
      Apertura_ant: "",
      ComunidadesAsig: [],
      ComunidadesNoAsig: [],
      horarios: [],
      horariosFestivos: [],
      leftChecked: [],
      rightChecked: [],
      left: [1, 2, 3, 4],
      leftOriginal: [],
      slots_seguidos: "",
      right: [5, 6, 711],
      hayCambio: false,
      checked: [],
      snack: false,
      snack2: false,
      vertical: "top",
      horizontal: "center",
      numreservasslot: "1",
      AntiguoAforo: "",
      antiguoTamañoSlot: -1,
      consumirReservaHoraActual: "",
      consumirDespuesCancelar: false,
      PermitirReservaHoraActual: "",
      margenCancelacion: false,
      horasCancelacion: 0,
      roles: [],
      Roles: [],
    };
    //this.handleChange = this.handleChange.bind(this);
    this.BuscarZonas = this.BuscarZonas.bind(this);
    this.borrarFiltros = this.borrarFiltros.bind(this);
    this.añadir = this.añadir.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.buscarComunidades = this.buscarComunidades.bind(this);
    this.traerZonas = this.traerZonas.bind(this);
    this.traerZonas2 = this.traerZonas2.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.anularZona = this.anularZona.bind(this);
    this.crearZonaReservas = this.crearZonaReservas.bind(this);
  }

  filterRef2() {
    return React.createRef("");
  }

  gestionZonas(comu) {
    this.setState({ abrir_cambios: false });
    this.setState({ mostrarTabla: false, open: true });

    this.props.firebase
      .users()
      .orderByChild("Comunidad")
      .equalTo(comu)
      .once("value", (snapshot) => {
        const usersObject = snapshot.val();

        var lista = [];

        if (usersObject) {
          lista = Object.keys(usersObject).map((key) => ({
            ...usersObject[key],
            uid: key,
          }));
        }

        const zona = this.state.zona;
        this.props.firebase.reservazona(comu + "/" + zona.uid).once("value", (snapshot) => {
          const usersObject = snapshot.val();

          if (usersObject) {
            const zonaList = Object.entries(usersObject).map(([key, val]) => ({
              Fecha: key,
              val,
            }));

            if (zonaList.length) {
              this.seleccionarZona(zonaList, lista);
            }
          } else {
            this.setState({ zonaReserva: [] });
          }
          this.setState({ abrir_zonas: true, abrir_cambios: false });
          this.setState({ mostrarTabla: true, open: false });
        });
      });
  }

  mostrarRegistroAcceso(registros) {
    let regs = [];
    const añosAccesos = [];
    if (registros) {
      Object.entries(registros).forEach(([key, value]) => {
        regs.push({
          ...value,
          idReg: key,
          fechaAperturaFormateada: new Date(value.fechaApertura).toLocaleString("default", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }),
        });
        const añoRegistro = new Date(value.fechaApertura).getFullYear();
        if (!añosAccesos.includes(añoRegistro)) {
          añosAccesos.push(añoRegistro);
        }
      });
    } else {
      console.log("No data available.");
    }
    this.setState({
      mostrarTabla: false,
      openRegistroAcceso: true,
      abrir_cambios: false,
      registrosAccesos: regs,
      registrosAccesosFiltrados: regs,
      añosAccesos: añosAccesos,
    });
  }

  seleccionarZona(zonaList, usuarios) {
    const zona = this.state.zona;
    this.setState({ nombreZona: zona.Nombre, años: [] });

    var lista = [];
    var año = "";
    var años = [];
    zonaList.forEach((zon) => {
      if (zon.Fecha !== "uid") {
        const fecha = zon.Fecha.slice(6, 8) + "/" + zon.Fecha.slice(4, 6) + "/" + zon.Fecha.slice(0, 4);
        const melon = zon.val;

        if (año !== zon.Fecha.slice(0, 4)) {
          años = años.concat({ Nombre: zon.Fecha.slice(0, 4) });
          año = zon.Fecha.slice(0, 4);
        }

        melon.forEach((hora, index) => {
          //   const reservas= zona.Aforo- hora.AforoActual

          var entrada = Object.entries(hora).map(([key, val]) => ({
            clave: key,
            val,
          }));

          entrada = entrada.filter((entr) => entr.clave !== "AforoActual" && entr.clave !== "hora");
          if (entrada && entrada.length > 0) {
            entrada.forEach((entr) => {
              const usu = usuarios.filter((usuario) => usuario.uid === entr.clave);

              var nombre = "";
              if (usu && usu.length > 0) {
                nombre = usu[0].Nombre;
                const cierre = melon[index + 1] ? melon[index + 1].hora : "24:00";
                lista = lista.concat({
                  Estado: entr.val.Cancelada ? "Cancelada" : "Ok",
                  Fecha: fecha,
                  Año: zon.Fecha.slice(0, 4),
                  Hora: hora.hora,
                  Usuario: nombre + " " + usu[0].Apellido,
                  Direccion: usu[0].Direccion,
                  Numero: usu[0].Numero,
                  Piso: usu[0].Piso,
                  Letra: usu[0].Letra,
                  Portal: usu[0].Portal,
                  DNI: usu[0].DNI,
                  Personas: entr.val.NumPersonas,
                  HoraFin: hora.hora_fin ? hora.hora_fin : cierre,
                });
              }
            });
          }
        });
      }
    });
    let añoActual = moment(new Date()).format("YYYY");
    const nuevaLista = lista.filter((zon) => zon.Año === añoActual);

    this.setState({
      listaZonasRecuperadasFiltrada: nuevaLista,
      añoActual: añoActual,
      listaZonasRecuperadas: lista,
      años: años,
    });
    this.setState({ abrir_zonas: true, abrir_cambios: false });
    this.setState({ mostrarTabla: true, open: false });
  }

  handleClose() {
    this.setState({ snack: false, snack2: false });
  }

  componentDidMount() {
    var rolesList = [];
    this.props.firebase.roles2().on("value", (snapshot) => {
      const usersObject = snapshot.val();

      if (usersObject) {
        rolesList = Object.keys(usersObject).map((key) => ({
          ...usersObject[key],
          uid: key,
        }));
        rolesList.sort(function (a, b) {
          if (a.Nombre > b.Nombre) {
            return 1;
          }
          if (a.Nombre < b.Nombre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        this.setState({ roles: rolesList });
      }
    });

    traerTutoriales(this, "Zonascomunes").then((data) => {
      this.setState({ VideoTuto: data });
    });
    this.buscarComunidades();
    this.traerZonas2();
  }

  buscarComunidades() {
    this.props.firebase.comus().once("value", (snapshot) => {
      const usersObject = snapshot.val();
      if (usersObject) {
        const comusList = Object.keys(usersObject).map((key) => ({
          ...usersObject[key],
          uid: key,
        }));
        comusList.sort(function (A, B) {
          var a = Number(A.Id.trim());
          var b = Number(B.Id.trim());
          if (!a) {
            a = -1;
          }
          if (!b) {
            b = -1;
          }
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          return 1;
        });
        if (comusList && comusList.length > 0) {
          var lista = [];
          var index1 = 0;
          comusList.map((comu, index) => {
            lista = lista.concat(<MenuItem value={index}>{comu.Nombre}</MenuItem>);
            index1 = index;
          });

          lista = lista.concat(<MenuItem value={index1 + 1}> </MenuItem>);

          this.setState({ menuComunidades: lista });
        }
        this.setState({ comus: comusList });
      } else {
        this.setState({ comus: [] });
      }
    });
  }
  componentWillUnmount() {
    this.props.firebase.roles2().off();
  }
  traerZonas() {
    this.props.firebase
      .zonascomunes()
      .once("value", (snapshot) => {
        const usersObject = snapshot.val();
        var zonasList = [];
        if (usersObject) {
          zonasList = Object.keys(usersObject).map((key) => ({
            ...usersObject[key],
            uid: key,
          }));
          zonasList.sort(function (a, b) {
            if (a.Nombre > b.Nombre) {
              return 1;
            }
            if (a.Nombre < b.Nombre) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        }

        this.setState({ zonas: zonasList });
      })
      .then(() => {
        this.setState({ mostrarTabla: true, open: false });
      });
  }
  traerZonas2() {
    this.props.firebase.zonascomunes().once("value", (snapshot) => {
      const usersObject = snapshot.val();
      var zonasList = [];
      if (usersObject) {
        zonasList = Object.keys(usersObject).map((key) => ({
          ...usersObject[key],
          uid: key,
        }));
        zonasList.sort(function (a, b) {
          if (a.Nombre > b.Nombre) {
            return 1;
          }
          if (a.Nombre < b.Nombre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }
      this.setState({ zonas: zonasList });
    });
  }
  BuscarZonas() {
    this.buscarComunidades();
    this.setState({ open: true, mostrarTabla: false, añadir: true });
    // this.borrarFiltros();
    this.traerZonas();
  }
  borrarFiltros() {
    this.setState({ ComunidadesNoAsig: [], ComunidadesAsig: [], zonas: [], nombre: "", descripcion: "" });
  }

  filtrarComunidad(listaComunidades) {
    var comunidadesAFiltrar = listaComunidades;
    var comunidades = this.state.comus.filter((comunidad) => comunidad.uid !== -1);
    var comunidades2 = this.state.comus.filter((comunidad) => comunidad.uid !== -1);

    if (!this.state.comus.empty) {
      if (comunidadesAFiltrar) {
        comunidades = comunidades.filter((comunidad) => comunidad.Id === comunidadesAFiltrar[comunidad.Id]);
        if (!comunidades.empty) {
          comunidades2 = comunidades2.filter((comunidad) => comunidad.Id !== comunidadesAFiltrar[comunidad.Id]);
          this.setState({
            left: comunidades,
            leftOriginal: comunidades,
            right: comunidades2,
            leftChecked: [],
            rightChecked: [],
            checked: [],
          });
        }
      } else {
        this.setState({
          left: [],
          right: comunidades2,
          leftChecked: [],
          leftOriginal: [],
          rightChecked: [],
          checked: [],
        });
      }
    }
    return true;
  }
  handleListItemClick(event, zona, zon) {
    //this.filtrarComunidad(zona.Comunidad)
    var comunidad = this.state.menuComunidades.length;
    if (zona.Comunidad) {
      const comus = this.state.comus;
      const zona2 = Object.entries(zona.Comunidad).map(([key, val]) => ({ Id: val }));

      if (zona2 && zona2.length > 0) {
        comunidad = comus.findIndex((comu) => comu.Id === zona2[0].Id);
      }
    }
    const AperturaPuerta = zona.AperturaPuerta;

    const HorariosLuz = zona.HorariosLuz;
    this.setState({ zona: zona, identIncial: zona.Nombre, hayCambio: false, AperturaPuerta: AperturaPuerta });
    this.setState({
      DespuesMinutos: AperturaPuerta && AperturaPuerta.DespuesMinutos ? AperturaPuerta.DespuesMinutos : 0,
      DesdeMinutos: AperturaPuerta && AperturaPuerta.DesdeMinutos ? AperturaPuerta.DesdeMinutos : 0,

      horariosLuzFin: HorariosLuz ? HorariosLuz.Fin : "00:00",
      horariosLuzInicio: HorariosLuz ? HorariosLuz.Inicio : "19:00",

      AperturaPuertaActivar: zona.AperturaPuertaActivar,

      AntiguoAforo: zona.Aforo,
      antiguoTamañoSlot: zona.Tamaño_slot,
      slots_seguidos: zona.Slots_seguidos,
      Apertura_ant: zona.Apertura,
      Cierre_ant: zona.Cierre,
      comunidad: comunidad,
      umbralInvitadosSlot: zona.umbralInvitadosSlot ? zona.umbralInvitadosSlot : 0,
      numeroConvivientes: zona.numeroConvivientes ? zona.numeroConvivientes : 0,
      controlPersonasAsistentes: zona.controlPersonasAsistentes ? zona.controlPersonasAsistentes : false,
      PermitirReservarHora: zona.PermitirReservarHora ? zona.PermitirReservarHora : "24:00",
      horariosFestivos: zona.horariosFestivos ? zona.horariosFestivos : [],
      controlStock: zona.controlStock ? zona.controlStock : false,
      controlAbonos: zona.controlAbonos ? zona.controlAbonos : false,
      pagoAbonoApp: zona.pagoAbonoApp ? zona.pagoAbonoApp : false,
      controlAbonosLuz: zona.controlAbonosLuz ? zona.controlAbonosLuz : false,
      inicioStock: zona.inicioStock ? zona.inicioStock : "",
      finStock: zona.finStock ? zona.finStock : "",
      stockLimite: zona.controlStock ? (zona.stockLimite ? zona.stockLimite : 1) : 1,
      tipoControlStock: zona.controlStock ? (zona.tipoControlStock ? zona.tipoControlStock : 1) : 1,
      sabados: zona.Sabados ? zona.Sabados : false,
      domingos: zona.Domingos ? zona.Domingos : false,
      festivos: zona.Festivos ? zona.Festivos : false,
      diasFestivos: zona.diasFestivos ? zona.diasFestivos : [],
      horarioFindes: zona.horarioFindes ? zona.horarioFindes : false,
      limitaciones: zona.Limitaciones,
      diasAntesSinConsumir: zona.diasAntesSinConsumir ? zona.diasAntesSinConsumir : 0,
      margenSinConsumir: zona.margenSinConsumir ? zona.margenSinConsumir : "24:00",
      LimitacionHora: zona.LimitacionHora ? zona.LimitacionHora : 0,
      Limitaciondias: zona.Limitaciondias ? zona.Limitaciondias : 0,
      consumirDespuesCancelar: zona.consumirDespuesCancelar ? zona.consumirDespuesCancelar : false,
      consumirReservaHoraActual: zona.consumirReservaHoraActual ? zona.consumirReservaHoraActual : false,
      PermitirReservaHoraActual: zona.PermitirReservaHoraActual ? zona.PermitirReservaHoraActual : false,
      NoPermitirSiReservaActiva: zona.NoPermitirSiReservaActiva ? zona.NoPermitirSiReservaActiva : false,
      mostrarPisoPortalPuerta: zona.mostrarPisoPortalPuerta ? zona.mostrarPisoPortalPuerta : false,
      margenCancelacion: zona.margenCancelacion ? zona.margenCancelacion : false,
      horasCancelacion: zona.horasCancelacion ? zona.horasCancelacion : 0,
      NoconsumirStockDia: zona.NoconsumirStockDia ? zona.NoconsumirStockDia : false,
      MostrarAbrirPuertaEnListado: zona.MostrarAbrirPuertaEnListado ? zona.MostrarAbrirPuertaEnListado : false,
      RegistroApertura: zona.RegistroApertura ? zona.RegistroApertura : null,
      Roles: zona.Roles ? zona.Roles : [],
    });

    this.setState({
      nombre: zona.Nombre,
      descripcion: zona.Descripcion,
      apertura: zona.Apertura,
      cierre: zona.Cierre,
      numreservas: zona.Numreservas,
      tipo_zona: zona.Tipo_zona,
      numpersonas: zona.Numpersonas,
      horizonte: zona.Horizonte,
      numreservasslot: zona.Aforo,
      horarios: zona.Horarios,
      tamaño_slot: zona.Tamaño_slot,
    });
    if (zon === "visualizar") {
      this.setState({ modificar: true });
    } else if (zon === "modificar") {
      this.setState({ modificar: false, tipo: "guardar" });
    }

    this.setState({ abrir_cambios: true });
  }

  validarControlStock() {
    if (!this.state.controlStock) {
      //No  se ha activado el control de stock devolvemos true  para que siga el proceso
      //Y dejamos los datos de la gestión de stocks en  valor inicial
      this.setState({ inicioStock: "", finStock: "", tipoControlStock: 1, stockLimite: 1 });
      return true;
    }

    if (this.state.stockLimite === 0 || this.state.stockLimite === "") {
      alert("Si usas el control de stock de reservas, el valor del límite de reservas debe ser al menos 1");
      return false;
    }

    if (this.state.tipoControlStock === 4) {
      if (this.state.inicioStock === "" || this.state.finStock === "") {
        alert("fecha del inicio o fin de la temporada de control de stock no puede ser vacía");
        return false;
      }
      if (this.state.inicioStock === this.state.finStock && this.state.finStock !== "") {
        alert("fecha del inicio y fin de la temporada de control de stock no puede ser la misma");
        return false;
      }
    }

    return true;
  }
  onSubmit = (event) => {
    let comun = this.state.comus[this.state.comunidad];
    let comunidad = [];
    comunidad[0] = comun ? comun.Id : [];
    var slots_seguidos = this.state.slots_seguidos;

    if (!slots_seguidos) {
      slots_seguidos = 1;
    }

    if (this.validarControlStock()) {
      let zona = { ...this.state.zona };
      this.setState({ abrir_cambios: false, open: true });
      zona["Abonos"] = !this.state.controlAbonos || !this.state.pagoAbonoApp ? [] : zona.Abonos ? zona.Abonos : [];

      zona["AbonosLuz"] =
        !this.state.controlAbonos || !this.state.pagoAbonoApp || !this.state.controlAbonosLuz
          ? []
          : zona.AbonosLuz
          ? zona.AbonosLuz
          : [];

      zona.pagoAbonoApp = !this.state.controlAbonos ? false : this.state.pagoAbonoApp ? this.state.pagoAbonoApp : false;
      zona.controlAbonosLuz = !this.state.controlAbonos
        ? false
        : this.state.controlAbonosLuz
        ? this.state.controlAbonosLuz
        : false;

      if (!zona.pagoAbonoApp) {
        this.setState({ pagoAbonoApp: false, Abonos: [] });
      }

      if (!zona.controlAbonosLuz) {
        this.setState({ controlAbonosLuz: false, AbonosLuz: [] });
      } else {
        const final = Number(this.state.horariosLuzFin.split(":")[0]) === 0;
        const nuevo = "24:" + this.state.horariosLuzFin.split(":")[1];
        zona["HorariosLuz"] = { Fin: final ? nuevo : this.state.horariosLuzFin, Inicio: this.state.horariosLuzInicio };
      }

      //hay que comprobar si está activo la limitacion por horas exacta entonces no cuenta el horizonte.
      let horizonte = this.state.horizonte;

      if (this.state.limitaciones === 5) {
        const nuevoHorizonte = this.state.LimitacionHora / 24 <= 1 ? 2 : this.state.LimitacionHora / 24 + 1;
        if (this.state.horizonte !== nuevoHorizonte) {
          horizonte = nuevoHorizonte;
          this.setState({ horizonte: nuevoHorizonte, hayCambio: true });
        }
      }

      const AperturaPuerta2 = zona.AperturaPuerta2;

      if (this.state.tipo === "guardar") {
        this.props.firebase
          .zonacomun(zona.uid)
          .update({
            Nombre: this.state.nombre,
            Descripcion: this.state.descripcion,
            Numreservas: this.state.numreservas,
            Numpersonas: this.state.numpersonas,
            Horizonte: horizonte,
            horarioFindes: this.state.horarioFindes ? this.state.horarioFindes : false,
            Comunidad: comunidad && comunidad.length > 0 ? comunidad : [],
            AperturaPuerta: !this.state.AperturaPuertaActivar
              ? null
              : {
                  DespuesMinutos: this.state.DespuesMinutos ? this.state.DespuesMinutos : 0,
                  DesdeMinutos: this.state.DesdeMinutos ? this.state.DesdeMinutos : 0,
                  TelefonoDestino: AperturaPuerta2.TelefonoDestino,
                  TelefonoOrigen: AperturaPuerta2.TelefonoOrigen,
                  UltimaApertura: AperturaPuerta2.UltimaApertura,
                  RegistroLlamada: AperturaPuerta2.RegistroLlamada,
                  PeticionEnCurso: AperturaPuerta2.PeticionEnCurso,
                },
            AperturaPuertaActivar: this.state.AperturaPuertaActivar ? this.state.AperturaPuertaActivar : false,
            Slots_seguidos: slots_seguidos,
            PermitirReservarHora: this.state.PermitirReservarHora ? this.state.PermitirReservarHora : "24:00",
            Limitaciones: this.state.limitaciones ? this.state.limitaciones : 1,
            Tipo_zona: this.state.tipo_zona,
            Aforo: this.state.numreservasslot,
            diasAntesSinConsumir: this.state.diasAntesSinConsumir ? this.state.diasAntesSinConsumir : 0,
            margenSinConsumir: this.state.margenSinConsumir ? this.state.margenSinConsumir : "24:00",
            consumirDespuesCancelar: this.state.consumirDespuesCancelar ? this.state.consumirDespuesCancelar : false,
            consumirReservaHoraActual: this.state.consumirReservaHoraActual
              ? this.state.consumirReservaHoraActual
              : false,
            PermitirReservaHoraActual: this.state.PermitirReservaHoraActual
              ? this.state.PermitirReservaHoraActual
              : false,
            NoPermitirSiReservaActiva: this.state.NoPermitirSiReservaActiva
              ? this.state.NoPermitirSiReservaActiva
              : false,
            mostrarPisoPortalPuerta:
              Number(this.state.numreservasslot) !== 1
                ? false
                : this.state.mostrarPisoPortalPuerta
                ? this.state.mostrarPisoPortalPuerta
                : false,

            horariosFestivos: this.state.horariosFestivos,
            diasFestivos: this.state.festivos === true ? this.state.diasFestivos : [],
            LimitacionHora: this.state.LimitacionHora ? this.state.LimitacionHora : 0,
            Limitaciondias: this.state.Limitaciondias ? this.state.Limitaciondias : 0,
            Sabados: this.state.sabados,
            Domingos: this.state.domingos,
            Festivos: this.state.festivos,
            Horarios: this.state.horarios,
            Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
            controlStock: this.state.controlStock ? this.state.controlStock : false,
            controlAbonos: this.state.controlAbonos ? this.state.controlAbonos : false,
            pagoAbonoApp: zona.pagoAbonoApp,
            controlAbonosLuz: zona.controlAbonosLuz,
            inicioStock: this.state.inicioStock ? this.state.inicioStock : "",
            finStock: this.state.finStock ? this.state.finStock : "",
            stockLimite: this.state.stockLimite ? this.state.stockLimite : 1,
            tipoControlStock: this.state.tipoControlStock ? this.state.tipoControlStock : 1,
            margenCancelacion: this.state.margenCancelacion ? this.state.margenCancelacion : false,
            horasCancelacion: this.state.horasCancelacion ? this.state.horasCancelacion : 0,
            NoconsumirStockDia: this.state.NoconsumirStockDia ? this.state.NoconsumirStockDia : false,
            TablaAbonos: zona.TablaAbonos ? zona.TablaAbonos : [],
            TablaAbonosLuz: zona.TablaAbonosLuz ? zona.TablaAbonosLuz : [],
            Abonos: zona.Abonos,
            AbonosLuz: zona.AbonosLuz,
            HorariosLuz: zona.HorariosLuz ? zona.HorariosLuz : null,

            umbralInvitadosSlot: this.state.umbralInvitadosSlot ? this.state.umbralInvitadosSlot : 0,
            controlPersonasAsistentes: this.state.controlPersonasAsistentes
              ? this.state.controlPersonasAsistentes
              : false,
            numeroConvivientes: this.state.numeroConvivientes ? this.state.numeroConvivientes : 0,
            Roles: this.state.Roles ? this.state.Roles : [],
          })
          .then(() => {
            this.setState({ modificar: true });
            //Eliminamos la relacion de la zona para las comunidad de la columna izquierda
            // this.anularyasociarComunidadDeZona(this.state.zona.uid)

            if (this.state.numreservasslot !== this.state.AntiguoAforo || this.state.hayCambio) {
              this.crearZonaReservas(
                [comunidad],
                this.state.zona.uid,
                this.state.numreservasslot,
                horizonte,
                this.state.apertura
              ).then(() => {
                //   this.setState({nombre:"", descripcion:"",
                // apertura:"07:00", cierre:"18:00", numreservas:"1",
                // numpersonas:"1",horizonte:1,    numreservasslot:"1" })
                this.setState({
                  snack: true,
                  hayCambio: false,
                  error2: "La zona " + this.state.nombre + " se ha actualizado correctamente",
                });
              });
            } else {
              //     this.setState({nombre:"", descripcion:"",
              //    apertura:"07:00", cierre:"18:00", numreservas:"1",
              //    numpersonas:"1",horizonte:1,    numreservasslot:"1" })
              this.setState({
                snack: true,
                error2: "La zona " + this.state.nombre + " se ha actualizado correctamente",
              });
            }

            this.setState({ abrir_cambios: true, open: false });

            this.setState({ zonas: [] }, () => {
              this.traerZonas();
            });
          })
          .catch((error) => {
            this.setState({ error });
            this.setState({ abrir_cambios: true, open: false });
          });
      } else {
        this.props.firebase
          .zonascomunes()
          .push({
            Nombre: this.state.nombre,
            Descripcion: this.state.descripcion,
            Numreservas: this.state.numreservas,
            Numpersonas: this.state.numpersonas,
            Horizonte: horizonte,
            horarioFindes: this.state.horarioFindes ? this.state.horarioFindes : false,
            AperturaPuerta: {
              DespuesMinutos: this.state.DespuesMinutos ? this.state.DespuesMinutos : 0,
              DesdeMinutos: this.state.DesdeMinutos ? this.state.DesdeMinutos : 0,
            },
            AperturaPuertaActivar: this.state.AperturaPuertaActivar ? this.state.AperturaPuertaActivar : false,
            AperturaPuerta2: {
              DespuesMinutos: this.state.DespuesMinutos ? this.state.DespuesMinutos : 0,
              DesdeMinutos: this.state.DesdeMinutos ? this.state.DesdeMinutos : 0,
            },

            Comunidad: comunidad && comunidad.length > 0 ? comunidad : [],
            diasAntesSinConsumir: this.state.diasAntesSinConsumir ? this.state.diasAntesSinConsumir : 0,
            margenSinConsumir: this.state.margenSinConsumir ? this.state.margenSinConsumir : "24:00",
            PermitirReservarHora: this.state.PermitirReservarHora ? this.state.PermitirReservarHora : "24:00",
            Slots_seguidos: slots_seguidos,
            Limitaciones: this.state.limitaciones,
            LimitacionHora: this.state.LimitacionHora ? this.state.LimitacionHora : 0,
            Limitaciondias: this.state.Limitaciondias ? this.state.Limitaciondias : 0,
            Tipo_zona: this.state.tipo_zona,
            Aforo: this.state.numreservasslot,
            horariosFestivos: this.state.horariosFestivos,
            diasFestivos: this.state.festivos === true ? this.state.diasFestivos : [],
            Sabados: this.state.sabados,
            Domingos: this.state.domingos,
            Festivos: this.state.festivos,
            Horarios: this.state.horarios,
            Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
            controlStock: this.state.controlStock ? this.state.controlStock : false,
            controlAbonos: this.state.controlAbonos ? this.state.controlAbonos : false,
            pagoAbonoApp: zona.pagoAbonoApp,
            controlAbonosLuz: zona.controlAbonosLuz,
            inicioStock: this.state.inicioStock ? this.state.inicioStock : "",
            finStock: this.state.finStock ? this.state.finStock : "",
            stockLimite: this.state.stockLimite ? this.state.stockLimite : 1,
            tipoControlStock: this.state.tipoControlStock ? this.state.tipoControlStock : 1,
            consumirDespuesCancelar: this.state.consumirDespuesCancelar ? this.state.consumirDespuesCancelar : false,
            consumirReservaHoraActual: this.state.consumirReservaHoraActual
              ? this.state.consumirReservaHoraActual
              : false,
            PermitirReservaHoraActual: this.state.PermitirReservaHoraActual
              ? this.state.PermitirReservaHoraActual
              : false,
            mostrarPisoPortalPuerta:
              Number(this.state.numreservasslot) !== 1
                ? false
                : this.state.mostrarPisoPortalPuerta
                ? this.state.mostrarPisoPortalPuerta
                : false,
            TablaAbonos: zona.TablaAbonos ? zona.TablaAbonos : [],
            TablaAbonosLuz: zona.TablaAbonosLuz ? zona.TablaAbonosLuz : [],
            Abonos: zona.Abonos,
            AbonosLuz: zona.AbonosLuz,
            HorariosLuz: zona.HorariosLuz ? zona.HorariosLuz : null,

            NoPermitirSiReservaActiva: this.state.NoPermitirSiReservaActiva
              ? this.state.NoPermitirSiReservaActiva
              : false,
            margenCancelacion: this.state.margenCancelacion ? this.state.margenCancelacion : false,
            horasCancelacion: this.state.horasCancelacion ? this.state.horasCancelacion : 0,
            NoconsumirStockDia: this.state.NoconsumirStockDia ? this.state.NoconsumirStockDia : false,
            umbralInvitadosSlot: this.state.umbralInvitadosSlot ? this.state.umbralInvitadosSlot : 0,
            controlPersonasAsistentes: this.state.controlPersonasAsistentes
              ? this.state.controlPersonasAsistentes
              : false,
            numeroConvivientes: this.state.numeroConvivientes ? this.state.numeroConvivientes : 0,
            Roles: this.state.Roles ? this.state.Roles : [],
          })
          .then((data) => {
            this.setState({ modificar: true });
            //          this.anularyasociarComunidadDeZona(data.key)

            this.setState({ snack: true, error2: "La zona " + this.state.nombre + " se ha creado correctamente" });
            this.setState({ abrir_cambios: false, open: false });
            this.setState({ zonas: [], zona: "" }, () => {
              this.traerZonas();
            });
          })
          .catch((error) => {
            this.setState({ abrir_cambios: true, open: false });
            this.setState({ error });
          });
      }
    }
  };
  crearZonaReservas(comunidad, uid, aforo, horizonte, apertura) {
    return new Promise((resolve) => {
      // if(comunidades&&comunidades.length>0)
      // {var comList = Object.keys(comunidades).map(key => ({
      //      uid: key,
      //    }));

      var currentDate = moment().locale("es");
      var fecha1 = currentDate.format("YYYYMMDD");

      var fechasAcomprobar = [];

      for (let i = 0; i < horizonte; i++) {
        fechasAcomprobar = fechasAcomprobar.concat(fecha1);
        fecha1 = moment()
          .add(+i + 1, "day")
          .format("YYYYMMDD");
      }
      const getData = async () => {
        return Promise.all(fechasAcomprobar.map((fecha) => this.comprobarFecha(fecha, uid, aforo, comunidad)));
      };
      getData().then((data) => {
        this.borrarFechas(fecha1, uid, comunidad);
        resolve(true);
      });
      // borramos las fechas que no están incluidas en el nuevo intervalo pero si existian en el  anterior a futuro
      //    this.borrarFechas(fecha1,uid,comList,horizonte)
    });
  }

  borrarFechas(fecha, uid, comList) {
    comList.map((comunidad) => {
      this.props.firebase
        .reservazona(comunidad + "/" + uid + "/")
        .orderByKey()
        .startAt(fecha)
        .once("value", (snapshot) => {
          const usersObject = snapshot.val();
          if (usersObject) {
            var fechasList = Object.keys(usersObject).map((key) => ({
              key,
            }));
            fechasList.sort(function (a, b) {
              if (a > b) {
                return 1;
              }
              if (a < b) {
                return -1;
              }
              // a must be equal to b
              return 0;
            });
            fechasList = fechasList.filter((fech) => fech.key >= fecha);
            fechasList.map((fechha) =>
              this.props.firebase.reservazona(comunidad + "/" + uid + "/" + fechha.key).remove()
            );
          }
        });
    });
  }
  comprobarFecha(fecha, uid, aforo, comunidad) {
    const zona = this.state.zona;
    //const comunidad= Object.entries(zona.Comunidad ).map(([key, val]) => ({   'com':val  }));
    var lanzadorReservas = {};

    var horarios = this.state.horarios;
    const horarioFindes = this.state.horarioFindes;

    const sabados = this.state.sabados;
    const domingos = this.state.domingos;
    const festivos = this.state.festivos;

    if (
      (horarioFindes && sabados && moment(fecha).day() === 6) ||
      (horarioFindes && domingos && moment(fecha).day() === 0)
    ) {
      horarios = this.state.horariosFestivos;
    } else if (horarioFindes && festivos) {
      const diasFestivos = this.state.diasFestivos;
      diasFestivos.forEach((dia) => {
        if (moment(dia).format("YYYYMMDD") === moment(fecha).format("YYYYMMDD")) {
          horarios = this.state.horariosFestivos;
        }
      });
    }

    var j = 0;
    horarios.map((horario) => {
      const tamaño = horario.Tamaño_slot;
      var hora = horario.Apertura;
      var cierre = horario.Cierre;
      if (cierre == "00:00") {
        cierre = "24:00";
      }
      let inicio = Number(hora.split(":")[0]) + Number(hora.split(":")[1]) / 60;

      var final = Number(cierre.split(":")[0]) + Number(cierre.split(":")[1]) / 60;
      if (final == 0) {
        final = 24;
      }

      const minutos = tamaño ? 60 * tamaño : 0;

      console.log(minutos, inicio, final, tamaño, hora, cierre);

      for (let i = 0; i < (final - inicio) / tamaño; i++) {
        var loqueviene = moment(hora, "HH:mm").add(minutos, "minutes").format("HH:mm");
        if (i + 1 >= (final - inicio) / tamaño) {
          let initialdate = moment(fecha).format("YYYY-MM-DD");
          let start_time = hora;
          let datetimeA = moment(initialdate + " " + start_time);
          let datetimeB = moment(datetimeA).add(minutos, "minutes");
          let datimeC = moment(initialdate + " " + cierre);

          //    console.log('cieere y ultimo',datetimeB.format('HH:mm'), datimeC.format('HH:mm'));

          let datetimeC = datetimeB.diff(datimeC, "hours");

          console.log(datetimeC);

          if (datetimeB >= datimeC) {
            loqueviene = cierre;
            // console.log(hora,'es mayor', loqueviene)
          }
        }

        lanzadorReservas[j] = {
          hora: hora,
          hora_fin: loqueviene,
          AforoActual: zona.Aforo,
        };
        j++;
        hora = loqueviene;
        // if(hora>=cierre){break}
      }
    });

    return new Promise((resolve) => {
      //  console.log(comunidad,zona.uid,fecha)

      resolve(this.props.firebase.reservazona(comunidad[0] + "/" + zona.uid + "/" + fecha).set(lanzadorReservas));
    });
  }

  onChange = (event) => {
    if (event.target.name === "numpersonas") {
      if (Number(this.state.numreservasslot) < Number(event.target.value)) {
        alert(
          "El número de personas permitido en una reserva " +
            event.target.value +
            " no puede ser mayor que el aforo permitido de la Zona: " +
            this.state.numreservasslot
        );
      } else {
        this.setState({ [event.target.name]: event.target.value });
      }
    } else if (event.target.name === "cierre") {
      var horacargar = "";
      if (this.state.apertura > event.target.value && event.target.value !== "00:00") {
        alert("La hora de cierrre no puede ser anterior a la hora de Apertura");
      } else {
        const hora = event.target.value;
        const min = hora.split(":");
        if (min[1] > 0 && min[1] < 30) {
          horacargar = min[0] + ":00";
        } else {
          horacargar = min[0] + ":30";
        }
        this.setState({ [event.target.name]: horacargar });
      }
    } else if (event.target.name === "apertura") {
      if (event.target.value > this.state.cierre && this.state.cierre !== "00:00") {
        alert("La hora de cierrre no puede ser anterior a la hora de Apertura");
      } else {
        const hora = event.target.value;
        const min = hora.split(":");
        if (min[1] > 0 && min[1] < 30) {
          horacargar = min[0] + ":00";
        } else {
          horacargar = min[0] + ":30";
        }
        this.setState({ [event.target.name]: horacargar });
      }
    } else if (event.target.name === "horizonte") {
      this.setState({ [event.target.name]: event.target.value, hayCambio: true });
    } else if (event.target.name === "stockLimite") {
      if (event.target.value !== "") {
        this.setState({ [event.target.name]: event.target.valueAsNumber });
      } else {
        this.setState({ [event.target.name]: event.target.value });
      }
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  onChangeList = (event, horario, index) => {
    var horarios = this.state.horarios;
    if (event.target.name === "cierre") {
      // if (horario.Apertura >  event.target.value && event.target.value!='00:00' )
      // { alert('La hora de cierre no puede ser anterior a la hora de Apertura')}
      // else{
      // var horacargar=''
      //    const hora = event.target.value
      //   const min = hora.split(':')
      // if(min[1]>0&&min[1]<30){horacargar= min[0]+':00'}
      // else{horacargar= min[0]+':30'}
      horario.Cierre = event.target.value === "00:00" ? "24:00" : event.target.value;
      horarios[index] = horario;

      if (horarios.length > 1) {
        var nuevosHorarios = [];
        nuevosHorarios = nuevosHorarios.concat(horarios[0]);
        horarios.forEach((horar, index) => {
          if (index > 0) {
            var nuevoHora = horar;
            nuevoHora.Apertura = horarios[index - 1].Cierre;
            if (nuevoHora.Apertura >= nuevoHora.Cierre && nuevoHora.Cierre !== "00:00") {
              var cierre = Number(nuevoHora.Apertura.split(":")[0]);
              cierre = cierre + 1;
              var cierrefinal = cierre + ":" + nuevoHora.Apertura.split(":")[1];
              if (cierre < 10) {
                cierrefinal = "0" + cierrefinal;
              } else if (cierre >= 24) {
                cierrefinal = "00:00";
              }
              nuevoHora.Cierre = cierrefinal;
            }
            horar = nuevoHora;
            nuevosHorarios = nuevosHorarios.concat(nuevoHora);
          }
        });
        this.setState({ horarios: nuevosHorarios, hayCambio: true });
      } else {
        this.setState({ horarios: horarios, hayCambio: true });
      }

      //   }
    } else if (event.target.name === "apertura") {
      //  if ( event.target.value >  horario.Cierre &&horario.Cierre!='00:00')
      //  { alert('La hora de cierrre no puede ser anterior a la hora de Apertura')}
      //  else{
      //   var horacargar=''
      //  const hora = event.target.value
      // const min = hora.split(':')
      // if(min[1]>0&&min[1]<30){horacargar= min[0]+':00'}
      // else{horacargar= min[0]+':30'}
      horario.Apertura = event.target.value;
      horarios[index] = horario;
      this.setState({ horarios: horarios, hayCambio: true });
    }

    // }
    else if (event.target.name === "tamaño_slot") {
      horario.Tamaño_slot = event.target.value;
      horarios[index] = horario;
      this.setState({ horarios: horarios, hayCambio: true });
    }
  };

  onChangeListFestivos = (event, horario, index) => {
    var horarios = this.state.horariosFestivos;
    if (event.target.name === "cierre_festivo") {
      if (horario.Apertura > event.target.value && event.target.value !== "00:00") {
        alert("La hora de cierre no puede ser anterior a la hora de Apertura");
      } else {
        //  var horacargar=''
        //  const hora = event.target.value
        //  const min = hora.split(':')
        //  if(min[1]>0&&min[1]<30){horacargar= min[0]+':00'}
        //  else{horacargar= min[0]+':30'}
        horario.Cierre = event.target.value === "00:00" ? "24:00" : event.target.value;
        horarios[index] = horario;

        if (horarios.length > 1) {
          var nuevosHorarios = [];
          nuevosHorarios = nuevosHorarios.concat(horarios[0]);
          horarios.forEach((horar, index) => {
            if (index > 0) {
              var nuevoHora = horar;
              nuevoHora.Apertura = horarios[index - 1].Cierre;
              if (nuevoHora.Apertura >= nuevoHora.Cierre && nuevoHora.Cierre !== "00:00") {
                var cierre = Number(nuevoHora.Apertura.split(":")[0]);
                cierre = cierre + 1;
                var cierrefinal = cierre + ":" + nuevoHora.Apertura.split(":")[1];
                if (cierre < 10) {
                  cierrefinal = "0" + cierrefinal;
                } else if (cierre >= 24) {
                  cierrefinal = "00:00";
                }
                nuevoHora.Cierre = cierrefinal;
              }
              horar = nuevoHora;
              nuevosHorarios = nuevosHorarios.concat(nuevoHora);
            }
          });
          this.setState({ horariosFestivos: nuevosHorarios, hayCambio: true });
        } else {
          this.setState({ horariosFestivos: horarios, hayCambio: true });
        }
      }
    } else if (event.target.name === "apertura_festivo") {
      if (event.target.value > horario.Cierre && horario.Cierre !== "00:00") {
        alert("La hora de cierrre no puede ser anterior a la hora de Apertura");
      } else {
        //  var horacargar=''
        //  const hora = event.target.value
        //   const min = hora.split(':')
        //  if(min[1]>0&&min[1]<30){horacargar= min[0]+':00'}
        //  else{horacargar= min[0]+':30'}
        horario.Apertura = event.target.value;
        horarios[index] = horario;
        this.setState({ horariosFestivos: horarios, hayCambio: true });
      }
    } else if (event.target.name === "tamaño_slot_festivo") {
      horario.Tamaño_slot = event.target.value;
      horarios[index] = horario;
      this.setState({ horariosFestivos: horarios, hayCambio: true });
    }
  };

  anularZona() {
    const zona = this.state.zona;
    if (zona.Comunidad && zona.Comunidad.length > 0) {
      const comus = this.state.comus;
      const zona2 = Object.entries(zona.Comunidad).map(([key, val]) => ({ Id: val }));
      const comunidad = comus.find((comu) => comu.Id === zona2[0].Id);

      this.props.firebase.comu(comunidad.uid + "/Zonas/" + zona.uid).remove();
    }

    this.props.firebase
      .zonacomun(this.state.zona.uid)
      .remove()

      .then(() => {
        this.setState({ snack2: false, snack: true, error2: "La zona " + this.state.nombre + " ha sido borrada" });
        this.BuscarZonas();
      })
      .catch((error) => {
        this.setState({ snack2: false, snack: true, error2: "Ha  habido un problema y no se ha podido borrar" });
        this.setState({ error });
      });
  }

  añadir() {
    this.setState({
      horizonte: 1,
      slots_seguidos: 1,
      hayCambio: true,
      tipoRegistro: "nuevo",
      abrir_cambios: true,
      comunidad: "",
      controlAbonos: false,
      abrirTablaAbonos: false,
      lista_abonos: [],
      lista_abonos_luz: [],
      pagoAbonoApp: false,
      abrirTablaTipoCobroAbono: false,
      lista_pagos_abonos: [],
      abrirTablaAbonosLuz: false,
      lista_pagos_abonos_luz: [],
      controlAbonosLuz: false,
      umbralInvitadosSlot: 0,
      DespuesMinutos: 0,
      DesdeMinutos: 0,
      AperturaPuertaActivar: false,
      controlPersonasAsistentes: false,
      consumirReservaHoraActual: false,
      consumirDespuesCancelar: false,
      PermitirReservaHoraActual: false,
      numeroConvivientes: 0,
      numpersonas: 1,
      numreservas: 1,
      stockLimite: 1,
      tipoControlStock: 0,
      comunidad: "",
      controlStock: false,
      numreservasslot: 1,
      modificar: false,
      nombre: "",
      descripcion: "",
      tipo: "añadir",
      tipo_zona: "",
      limitaciones: 1,
      PermitirReservarHora: "24:00",
      horarioFindes: false,
      diasFestivos: [],
      horarios: [
        {
          Apertura: "07:00",
          Cierre: "18:00",
          Tamaño_slot: 1,
        },
      ],
      horariosFestivos: [
        {
          Apertura: "07:00",
          Cierre: "18:00",
          Tamaño_slot: 1,
        },
      ],
      Roles: [],
    });
    this.filtrarComunidad();
  }
  cancelar() {
    this.setState({
      añadir: true,
      mostrarTabla: true,
      horarioFindes: false,
      hayCambio: false,
      modificar: true,
      diasFestivos: [],
    });
    this.borrarFiltros();
  }

  addHorario = () => {
    var horarios = this.state.horarios;
    const horario = horarios[horarios.length - 1];
    var cierre = Number(horario.Cierre.split(":")[0]);
    cierre = cierre + 1;
    var cierrefinal = cierre + ":" + horario.Cierre.split(":")[1];
    if (cierre < 10) {
      cierrefinal = "0" + cierrefinal;
    } else if (cierre >= 24) {
      cierrefinal = "00:00";
    }

    horarios = horarios.concat({ Apertura: horario.Cierre, Cierre: cierrefinal, noModificar: true, Tamaño_slot: 1 });
    this.setState({ horarios: horarios, hayCambio: true });
  };
  removeHorario = () => {
    var horarios = this.state.horarios;
    horarios.pop();
    this.setState({ horarios: horarios, hayCambio: true });
  };

  traerUsuarios(zona) {
    const zona2 = Object.entries(zona.Comunidad).map(([key, val]) => ({ id: val }));

    this.props.firebase
      .users()
      .orderByChild("/Comunidad")
      .equalTo(zona2[0].id)
      .once("value", (snapshot) => {
        const usersObject = snapshot.val();
        if (usersObject) {
          var usuarios = Object.keys(usersObject).map((key) => ({
            ...usersObject[key],
            uid: key,
          }));

          usuarios = usuarios.filter((usu) => usu.Borrado === false);

          usuarios.sort(function (A, B) {
            var a = A.Nombre.trim();
            var b = B.Nombre.trim();
            if (!a) {
              a = -1;
            }
            if (!b) {
              b = -1;
            }
            if (a > b) {
              return 1;
            }
            if (a < b) {
              return -1;
            }
            return 1;
          });

          this.setState({ usuarios: usuarios });
        }
      });
  }

  render() {
    const TablaTipoCobroAbonoLuz = () => {
      this.filterRef2["Cantidad"] = "";
      this.filterRef2["Valor"] = "";

      return (
        <Paper style={{ height: "70%", marginTop: "-10%", maxWidth: "42%" }}>
          <Paper style={{ height: "100%", overflowY: "auto" }}>
            <MaterialTable
              components={{
                Body: (props) => (
                  <MTableBody
                    {...props}
                    onFilterChanged={(columnId, value) => {
                      props.onFilterChanged(columnId, value);
                      const field = props.columns[columnId].field;
                      this.filterRef2[field] = value;
                    }}
                  />
                ),
              }}
              options={{
                doubleHorizontalScroll: false,
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                addRowPosition: "first",
                filtering: true,
                selection: false,
                pageSize: 15,
                exportDelimiter: ";",
                exportAllData: true,
                maxBodyHeight: "100vh",
                pageSizeOptions: [5, 15, 20],
                headerStyle: {
                  backgroundColor: "#00a049",
                  color: "white",
                  paddingTop: 5,
                  paddingBottom: 5,
                },
              }}
              icons={tableIcons}
              localization={{
                body: { editRow: { deleteText: "¿Quieres borrar el tipo de abono?" } },
                header: {
                  actions: "Acciones",
                },
              }}
              title={<h4 style={{ color: "#00a049" }}>TABLA TIPO DE COBRO DE ABONOS</h4>}
              columns={[
                {
                  title: "Reservas disponible por abono",
                  field: "Cantidad",
                  defaultFilter: this.filterRef2["Cantidad"],
                  validate: (rowData) =>
                    !rowData.Cantidad || rowData.Cantidad === "0"
                      ? { isValid: false, helperText: "Cantidad de reservas por abono no puede ser vacío ni 0" }
                      : true,
                },
                {
                  title: "Coste en euros",
                  field: "Valor",
                  defaultFilter: this.filterRef2["Valor"],
                  validate: (rowData) =>
                    !rowData.Valor || rowData.Valor === "0"
                      ? { isValid: false, helperText: "Coste del abono no puede ser vacío ni 0" }
                      : true,
                },
              ]}
              data={this.state.lista_pagos_abonos_luz}
              editable={{
                onRowUpdate: this.state.modificar
                  ? undefined
                  : (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          var user = this.props.firebase.auth.currentUser;

                          let zona = this.state.zona;
                          const lista_nueva = [...this.state.lista_pagos_abonos_luz];

                          var ejecuta = true;
                          if (!newData.Valor || newData.Valor === 0) {
                            alert("Campo coste no puede ser vacío o 0");
                            ejecuta = false;
                          } else if (!newData.Cantidad || newData.Cantidad === 0) {
                            alert("Campo entradas por abono no puede ser vacío o 0");
                            ejecuta = false;
                          }

                          const index = lista_nueva.findIndex((abono) => abono.Cantidad === oldData.Cantidad);
                          const dato = {
                            Valor: newData.Valor,
                            Cantidad: newData.Cantidad,
                          };

                          lista_nueva[index] = {};

                          lista_nueva[index] = dato;
                          const esta = lista_nueva.filter((abono) => abono.Cantidad === newData.Cantidad);

                          if (esta && esta.length > 1) {
                            alert("Existe ya un registro con esta cantidad de entradas por abono");
                            ejecuta = false;
                          }
                          if (ejecuta) {
                            alert(
                              'La tabla ha sido actualizada correctamente, para guardar los cambios debes "CERRAR" esta ventana  y  escoger "GUARDAR" la transacción, gracias.'
                            );
                            zona.AbonosLuz = lista_nueva;
                            this.setState({ lista_pagos_abonos_luz: lista_nueva, zona: zona });
                            resolve();
                          } else {
                            reject();
                          }

                          /* setData([...data, newData]); */
                        }, 1000);
                      }),
                onRowDelete: this.state.modificar
                  ? undefined
                  : (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          let zona = this.state.zona;
                          var lista = this.state.lista_pagos_abonos_luz;
                          const index = lista.indexOf(oldData);
                          lista.splice(index, 1);
                          zona.AbonosLuz = lista;
                          this.setState({ lista_pagos_abonos_luz: lista, zona: zona });
                          alert(
                            'La tabla ha sido actualizada correctamente, para guardar los cambios debes "CERRAR" esta ventana  y  escoger "GUARDAR" la transacción, gracias.'
                          );

                          resolve();
                        }, 1);
                      }),
                onRowAdd: this.state.modificar
                  ? undefined
                  : (newData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          let zona = this.state.zona;
                          var lista_nueva = [...this.state.lista_pagos_abonos_luz];
                          var ejecuta = true;
                          if (!newData.Valor || newData.Valor === 0) {
                            alert("Campo coste no puede ser vacío o 0");
                            ejecuta = false;
                          } else if (!newData.Cantidad || newData.Cantidad === 0) {
                            alert("Campo entradas por abono no puede ser vacío o 0");
                            ejecuta = false;
                          }
                          const dato = {
                            Valor: newData.Valor,
                            Cantidad: newData.Cantidad,
                          };
                          lista_nueva = [dato].concat(lista_nueva);
                          const esta = lista_nueva.filter((abono) => abono.Cantidad === newData.Cantidad);

                          if (esta && esta.length > 1) {
                            alert("Existe ya un registro con esta cantidad de entradas por abono");
                            ejecuta = false;
                          }
                          if (ejecuta) {
                            alert(
                              'El registro se ha creado correctamente, para guardar los cambios debes "CERRAR" esta ventana  y  escoger "GUARDAR" la transacción, gracias.'
                            );
                            zona.AbonosLuz = lista_nueva;
                            this.setState({ lista_pagos_abonos_luz: lista_nueva, zona: zona });
                            resolve();
                          } else {
                            reject();
                          }
                        }, 1);
                      }),
              }}
            />
          </Paper>

          <Button
            variant="contained"
            onClick={(e) => {
              this.setState({ abrirTablaTipoCobroAbonoLuz: false });
            }}
            color="primary"
            style={{ top: "1%" }}
          >
            Cerrar
          </Button>
        </Paper>
      );
    };
    const TablaTipoCobroAbono = () => {
      this.filterRef2["Cantidad"] = "";
      this.filterRef2["Valor"] = "";

      return (
        <Paper style={{ height: "70%", marginTop: "-10%", maxWidth: "42%" }}>
          <Paper style={{ height: "100%", overflowY: "auto" }}>
            <MaterialTable
              components={{
                Body: (props) => (
                  <MTableBody
                    {...props}
                    onFilterChanged={(columnId, value) => {
                      props.onFilterChanged(columnId, value);
                      const field = props.columns[columnId].field;
                      this.filterRef2[field] = value;
                    }}
                  />
                ),
              }}
              options={{
                doubleHorizontalScroll: false,
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                addRowPosition: "first",
                filtering: true,
                selection: false,
                pageSize: 15,
                exportDelimiter: ";",
                exportAllData: true,
                maxBodyHeight: "100vh",
                pageSizeOptions: [5, 15, 20],
                headerStyle: {
                  backgroundColor: "#00a049",
                  color: "white",
                  paddingTop: 5,
                  paddingBottom: 5,
                },
              }}
              icons={tableIcons}
              localization={{
                body: { editRow: { deleteText: "¿Quieres borrar el tipo de abono?" } },
                header: {
                  actions: "Acciones",
                },
              }}
              title={<h4 style={{ color: "#00a049" }}>TABLA TIPO DE COBRO DE ABONOS</h4>}
              columns={[
                {
                  title: "Reservas disponible por abono",
                  field: "Cantidad",
                  defaultFilter: this.filterRef2["Cantidad"],
                  validate: (rowData) =>
                    !rowData.Cantidad || rowData.Cantidad === "0"
                      ? { isValid: false, helperText: "Cantidad de reservas por abono no puede ser vacío ni 0" }
                      : true,
                },
                {
                  title: "Coste en euros",
                  field: "Valor",
                  defaultFilter: this.filterRef2["Valor"],
                  validate: (rowData) =>
                    !rowData.Valor || rowData.Valor === "0"
                      ? { isValid: false, helperText: "Coste del abono no puede ser vacío ni 0" }
                      : true,
                },
              ]}
              data={this.state.lista_pagos_abonos}
              editable={{
                onRowUpdate: this.state.modificar
                  ? undefined
                  : (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          var user = this.props.firebase.auth.currentUser;

                          let zona = this.state.zona;
                          const lista_nueva = [...this.state.lista_pagos_abonos];

                          var ejecuta = true;
                          if (!newData.Valor || newData.Valor === 0) {
                            alert("Campo coste no puede ser vacío o 0");
                            ejecuta = false;
                          } else if (!newData.Cantidad || newData.Cantidad === 0) {
                            alert("Campo entradas por abono no puede ser vacío o 0");
                            ejecuta = false;
                          }

                          const index = lista_nueva.findIndex((abono) => abono.Cantidad === oldData.Cantidad);
                          const dato = {
                            Valor: newData.Valor,
                            Cantidad: newData.Cantidad,
                          };

                          lista_nueva[index] = {};

                          lista_nueva[index] = dato;
                          const esta = lista_nueva.filter((abono) => abono.Cantidad === newData.Cantidad);

                          if (esta && esta.length > 1) {
                            alert("Existe ya un registro con esta cantidad de entradas por abono");
                            ejecuta = false;
                          }
                          if (ejecuta) {
                            alert(
                              'La tabla ha sido actualizada correctamente, para guardar los cambios debes "CERRAR" esta ventana  y  escoger "GUARDAR" la transacción, gracias.'
                            );
                            zona.Abonos = lista_nueva;
                            this.setState({ lista_pagos_abonos: lista_nueva, zona: zona });
                            resolve();
                          } else {
                            reject();
                          }

                          /* setData([...data, newData]); */
                        }, 1000);
                      }),
                onRowDelete: this.state.modificar
                  ? undefined
                  : (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          let zona = this.state.zona;
                          var lista = this.state.lista_pagos_abonos;
                          const index = lista.indexOf(oldData);
                          lista.splice(index, 1);
                          zona.Abonos = lista;
                          this.setState({ lista_pagos_abonos: lista, zona: zona });
                          alert(
                            'La tabla ha sido actualizada correctamente, para guardar los cambios debes "CERRAR" esta ventana  y  escoger "GUARDAR" la transacción, gracias.'
                          );

                          resolve();
                        }, 1);
                      }),
                onRowAdd: this.state.modificar
                  ? undefined
                  : (newData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          let zona = this.state.zona;
                          var lista_nueva = [...this.state.lista_pagos_abonos];
                          var ejecuta = true;
                          if (!newData.Valor || newData.Valor === 0) {
                            alert("Campo coste no puede ser vacío o 0");
                            ejecuta = false;
                          } else if (!newData.Cantidad || newData.Cantidad === 0) {
                            alert("Campo entradas por abono no puede ser vacío o 0");
                            ejecuta = false;
                          }
                          const dato = {
                            Valor: newData.Valor,
                            Cantidad: newData.Cantidad,
                          };
                          lista_nueva = [dato].concat(lista_nueva);
                          const esta = lista_nueva.filter((abono) => abono.Cantidad === newData.Cantidad);

                          if (esta && esta.length > 1) {
                            alert("Existe ya un registro con esta cantidad de entradas por abono");
                            ejecuta = false;
                          }
                          if (ejecuta) {
                            alert(
                              'El registro se ha creado correctamente, para guardar los cambios debes "CERRAR" esta ventana  y  escoger "GUARDAR" la transacción, gracias.'
                            );
                            zona.Abonos = lista_nueva;
                            this.setState({ lista_pagos_abonos: lista_nueva, zona: zona });
                            resolve();
                          } else {
                            reject();
                          }
                        }, 1);
                      }),
              }}
            />
          </Paper>

          <Button
            variant="contained"
            onClick={(e) => {
              this.setState({ abrirTablaTipoCobroAbono: false });
            }}
            color="primary"
            style={{ top: "1%" }}
          >
            Cerrar
          </Button>
        </Paper>
      );
    };
    const TablaAbonos = () => {
      this.filterRef2["abonado"] = "";
      this.filterRef2["dni"] = "";
      this.filterRef2["codigo"] = "";
      this.filterRef2["estado"] = "";
      this.filterRef2["stock"] = "";
      // this.filterRef2['porcentaje']=''
      this.filterRef2["observacionesII"] = "";
      return (
        <Paper style={{ height: "70%", marginTop: "-10%", width: "80%" }}>
          <Paper style={{ height: "100%", overflowY: "auto" }}>
            <MaterialTable
              components={{
                Body: (props) => (
                  <MTableBody
                    {...props}
                    onFilterChanged={(columnId, value) => {
                      props.onFilterChanged(columnId, value);
                      const field = props.columns[columnId].field;
                      this.filterRef2[field] = value;
                    }}
                  />
                ),
              }}
              options={{
                doubleHorizontalScroll: false,
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                addRowPosition: "first",
                filtering: true,
                selection: false,
                pageSize: 15,
                exportDelimiter: ";",
                exportAllData: true,
                maxBodyHeight: "100vh",
                pageSizeOptions: [5, 15, 20],
                headerStyle: {
                  backgroundColor: "#00a049",
                  color: "white",
                  paddingTop: 5,
                  paddingBottom: 5,
                },
              }}
              icons={tableIcons}
              localization={{
                body: { editRow: { deleteText: "¿Quieres borrar la estructura de dirección?" } },
                header: {
                  actions: "Acciones",
                },
              }}
              title={<h4 style={{ color: "#00a049" }}>TABLA DE ABONOS</h4>}
              columns={[
                {
                  title: "Abonado",
                  field: "abonado",
                  defaultFilter: this.filterRef2["abonado"],
                  validate: (rowData) =>
                    !rowData.abonado ? { isValid: false, helperText: "Abonado no puede ser vacío" } : true,
                  editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                    <Select
                      //   disabled={!rowData.comunidad || rowData.estado=== "CANCELADA" || rowData.estado=== "CERRADA"}
                      value={rowData.abonado}
                      onChange={(e) => {
                        var newRowData = { ...rowData };
                        const usuario = this.state.usuarios.find(
                          (usu) => usu.Nombre + " " + usu.Apellido === e.target.value
                        );
                        rowData.abonado = usuario.Nombre + " " + usuario.Apellido;
                        newRowData.abonado = usuario.Nombre + " " + usuario.Apellido;
                        newRowData.dni = usuario.DNI;

                        onRowDataChange(newRowData);
                      }}
                    >
                      {this.state.usuarios &&
                        this.state.usuarios.map((usu) => {
                          const usuario = this.state.lista_abonos.find(
                            (usu2) => usu.Nombre + " " + usu.Apellido === usu2.abonado
                          );
                          if (!usuario) {
                            return (
                              <MenuItem value={usu.Nombre + " " + usu.Apellido}>
                                {usu.Nombre} {usu.Apellido}
                              </MenuItem>
                            );
                          }
                        })}
                    </Select>
                  ),
                },

                { title: "DNI", field: "dni", editable: "never", defaultFilter: this.filterRef2["dni"] },
                {
                  title: "Código Abono",
                  field: "codigo",
                  type: "numeric",
                  defaultFilter: this.filterRef2["codigo"],
                  validate: (rowData) =>
                    !rowData.codigo ? { isValid: false, helperText: "Código no puede ser vacío" } : true,
                },
                {
                  title: "Estado",
                  field: "estado",
                  defaultFilter: this.filterRef2["estado"],
                  initialEditValue: "ABIERTO",
                  editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                    <FormControl>
                      <Select
                        //   disabled={!rowData.comunidad || rowData.estado=== "CANCELADA" || rowData.estado=== "CERRADA"}
                        value={rowData.estado}
                        onChange={(e) => {
                          const newRowData = { ...rowData, estado: e.target.value };
                          rowData.estado = e.target.value;

                          onRowDataChange(newRowData);
                        }}
                      >
                        {[
                          <MenuItem value="ABIERTO">ABIERTO</MenuItem>,
                          <MenuItem value="BLOQUEADO">BLOQUEADO</MenuItem>,
                        ]}
                      </Select>
                    </FormControl>
                  ),
                },

                //  { title: 'Letra', field: 'letra', defaultFilter: this.filterRef2['letra']},

                {
                  title: "Stock Actual",
                  field: "stock",
                  type: "numeric",
                  defaultFilter: this.filterRef2["stock"],
                  validate: (rowData) =>
                    (rowData.stock !== 0 && !rowData.stock) || rowData.stock < 0
                      ? { isValid: false, helperText: "No puede ser vacío o menor que 0" }
                      : true,
                },
                { title: "Observaciones", field: "observacionesII", defaultFilter: this.filterRef2["observacionesII"] },
              ]}
              detailPanel={[
                {
                  tooltip: "Abrir Detalle",
                  render: (rowData) => {
                    let id_pdf = "Pdf_doc_" + rowData.codigo;
                    var observaciones = rowData.observaciones;
                    observaciones = observaciones.split("\n");

                    return (
                      <div
                        style={{
                          fontSize: 15,
                          padding: 10,
                          background: "#f5da55",
                          color: "primary",
                          backgroundColor: "azure",
                          marginLeft: 30,
                          marginRight: 30,
                        }}
                      >
                        <div
                          id={id_pdf}
                          style={{
                            fontSize: 15,
                            padding: 10,
                            background: "#f5da55",
                            textAlign: "start",
                            color: "primary",
                            backgroundColor: "azure",
                            marginLeft: 30,
                            marginRight: 30,
                            maxWidth: 900,
                            //                                          maxHeight:600
                          }}
                        >
                          <Box fontWeight="fontWeightBold" m={1}>
                            <h3 style={{ color: "red" }}>LOG de Registros</h3>
                            {observaciones.map((valor) => {
                              return <p>{valor}</p>;
                            })}
                          </Box>
                        </div>
                      </div>
                    );
                  },
                },
              ]}
              data={this.state.lista_abonos}
              editable={{
                onRowUpdate: this.state.modificar
                  ? undefined
                  : (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          var user = this.props.firebase.auth.currentUser;

                          let zona = this.state.zona;
                          // write your logic here
                          //    this.setState({lista_final:[]})
                          //   const cambios= Object.values(changes)
                          //     const resul=false//validarCambio(cambios,this.state.lista_final)
                          const lista_nueva = [...this.state.lista_abonos];

                          var ejecuta = true;
                          if (!newData.estado) {
                            alert("Campo Estado no puede ser vacío");
                            ejecuta = false;
                          } else if (!newData.codigo) {
                            alert("Campo Código no puede ser vacío");
                            ejecuta = false;
                          } else if (!newData.stock) {
                            alert("Campo Stock no puede ser vacío");
                            ejecuta = false;
                          } else if (!newData.abonado) {
                            alert("Campo Abonado no puede ser vacío");
                            ejecuta = false;
                          }
                          const index = lista_nueva.findIndex((abono) => abono.dni === oldData.dni);
                          let cambioLog = "";
                          if (newData.estado !== oldData.estado) {
                            cambioLog = "Cambio de estado a " + newData.estado;
                          }
                          if (newData.abonado !== oldData.abonado) {
                            cambioLog = cambioLog + "Cambio de abonado a " + newData.abonado;
                          }
                          if (newData.codigo !== oldData.codigo) {
                            cambioLog = cambioLog + "Cambio de código a " + newData.codigo;
                          }
                          if (newData.stock !== oldData.stock) {
                            cambioLog = cambioLog + "Cambio de stock a " + newData.stock;
                          }

                          const dato = {
                            estado: newData.estado,
                            abonado: newData.abonado,
                            codigo: newData.codigo,
                            dni: newData.dni,
                            stock: newData.stock,
                            observaciones:
                              oldData.observaciones +
                              (cambioLog !== ""
                                ? moment(new Date()).format("DD-MM-YYYY HH:mm") +
                                  "<" +
                                  user.displayName +
                                  "> " +
                                  cambioLog +
                                  "\n"
                                : ""),
                            observacionesII: newData.observacionesII ? newData.observacionesII : "",
                          };

                          lista_nueva[index] = {};

                          lista_nueva[index] = dato;
                          const esta = lista_nueva.filter((abono) => abono.dni === newData.dni);

                          if (esta && esta.length > 1) {
                            alert("Existe ya un registro abono para este usuario");
                            ejecuta = false;
                          }
                          if (ejecuta) {
                            alert(
                              'La tabla ha sido actualizada correctamente, para guardar los cambios debes "CERRAR" esta ventana  y  escoger "GUARDAR" la transacción, gracias.'
                            );
                            zona.TablaAbonos = lista_nueva;
                            this.setState({ lista_abonos: lista_nueva, zona: zona });
                            resolve();
                          } else {
                            reject();
                          }

                          /* setData([...data, newData]); */
                        }, 1000);
                      }),
                onRowDelete: this.state.modificar
                  ? undefined
                  : (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          let zona = this.state.zona;
                          var lista = this.state.lista_abonos;
                          const index = lista.indexOf(oldData);
                          lista.splice(index, 1);
                          zona.TablaAbonos = lista;
                          this.setState({ lista_abonos: lista, zona: zona });
                          alert(
                            'La tabla ha sido actualizada correctamente, para guardar los cambios debes "CERRAR" esta ventana  y  escoger "GUARDAR" la transacción, gracias.'
                          );

                          resolve();
                        }, 1);
                      }),
                onRowAdd: this.state.modificar
                  ? undefined
                  : (newData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          var user = this.props.firebase.auth.currentUser;
                          var lista = this.state.lista_abonos;
                          let zona = this.state.zona;
                          var ejecuta = true;
                          if (!newData.estado) {
                            alert("Campo Estado no puede ser vacio");
                            ejecuta = false;
                          } else if (!newData.codigo) {
                            alert("Campo Código no puede ser vacio");
                            ejecuta = false;
                          } else if (!newData.stock) {
                            alert("Campo Stock no puede ser vacio");
                            ejecuta = false;
                          } else if (!newData.abonado) {
                            alert("Campo Abonado no puede ser vacio");
                            ejecuta = false;
                          }

                          if (ejecuta) {
                            const dato = {
                              estado: newData.estado,
                              abonado: newData.abonado,
                              codigo: newData.codigo,
                              dni: newData.dni,
                              stock: newData.stock,
                              observaciones:
                                moment(new Date()).format("DD-MM-YYYY HH:mm") +
                                " <" +
                                user.displayName +
                                "> Alta nuevo abono via plataforma web, recarga inicial " +
                                newData.stock,
                              observacionesII: newData.observacionesII ? newData.observacionesII : "",
                            };

                            lista = lista.concat(dato);
                            zona.TablaAbonos = lista;
                            this.setState({ lista_abonos: lista, zona: zona });
                            alert(
                              'La tabla ha sido actualizada correctamente, para guardar los cambios debes "CERRAR" esta ventana  y  escoger "GUARDAR" la transacción, gracias.'
                            );
                            resolve();
                          } else {
                            reject();
                          }
                        }, 1);
                      }),
              }}
            />
          </Paper>

          <Button
            variant="contained"
            onClick={(e) => {
              this.setState({ abrirTablaAbonos: false });
            }}
            color="primary"
            style={{ top: "1%" }}
          >
            Cerrar
          </Button>
        </Paper>
      );
    };

    const TablaAbonosLuz = () => {
      this.filterRef2["abonado"] = "";
      this.filterRef2["dni"] = "";
      this.filterRef2["codigo"] = "";
      this.filterRef2["estado"] = "";
      this.filterRef2["stock"] = "";
      // this.filterRef2['porcentaje']=''
      this.filterRef2["observacionesII"] = "";
      return (
        <Paper style={{ height: "70%", marginTop: "-10%", width: "80%" }}>
          <Paper style={{ height: "100%", overflowY: "auto" }}>
            <MaterialTable
              components={{
                Body: (props) => (
                  <MTableBody
                    {...props}
                    onFilterChanged={(columnId, value) => {
                      props.onFilterChanged(columnId, value);
                      const field = props.columns[columnId].field;
                      this.filterRef2[field] = value;
                    }}
                  />
                ),
              }}
              options={{
                doubleHorizontalScroll: false,
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                addRowPosition: "first",
                filtering: true,
                selection: false,
                pageSize: 15,
                exportDelimiter: ";",
                exportAllData: true,
                maxBodyHeight: "100vh",
                pageSizeOptions: [5, 15, 20],
                headerStyle: {
                  backgroundColor: "#00a049",
                  color: "white",
                  paddingTop: 5,
                  paddingBottom: 5,
                },
              }}
              icons={tableIcons}
              localization={{
                body: { editRow: { deleteText: "¿Quieres borrar el abono?" } },
                header: {
                  actions: "Acciones",
                },
              }}
              title={<h4 style={{ color: "#00a049" }}>TABLA DE ABONOS LUZ</h4>}
              columns={[
                {
                  title: "Abonado",
                  field: "abonado",
                  defaultFilter: this.filterRef2["abonado"],
                  validate: (rowData) =>
                    !rowData.abonado ? { isValid: false, helperText: "Abonado no puede ser vacío" } : true,
                  editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                    <Select
                      //   disabled={!rowData.comunidad || rowData.estado=== "CANCELADA" || rowData.estado=== "CERRADA"}
                      value={rowData.abonado}
                      onChange={(e) => {
                        var newRowData = { ...rowData };
                        const usuario = this.state.usuarios.find(
                          (usu) => usu.Nombre + " " + usu.Apellido === e.target.value
                        );
                        rowData.abonado = usuario.Nombre + " " + usuario.Apellido;
                        newRowData.abonado = usuario.Nombre + " " + usuario.Apellido;
                        newRowData.dni = usuario.DNI;

                        onRowDataChange(newRowData);
                      }}
                    >
                      {this.state.usuarios &&
                        this.state.usuarios.map((usu) => {
                          const usuario = this.state.lista_abonos_luz.find(
                            (usu2) => usu.Nombre + " " + usu.Apellido === usu2.abonado
                          );
                          if (!usuario) {
                            return (
                              <MenuItem value={usu.Nombre + " " + usu.Apellido}>
                                {usu.Nombre} {usu.Apellido}
                              </MenuItem>
                            );
                          }
                        })}
                    </Select>
                  ),
                },

                { title: "DNI", field: "dni", editable: "never", defaultFilter: this.filterRef2["dni"] },
                {
                  title: "Código Abono",
                  field: "codigo",
                  type: "numeric",
                  defaultFilter: this.filterRef2["codigo"],
                  validate: (rowData) =>
                    !rowData.codigo ? { isValid: false, helperText: "Código no puede ser vacío" } : true,
                },
                {
                  title: "Estado",
                  field: "estado",
                  defaultFilter: this.filterRef2["estado"],
                  initialEditValue: "ABIERTO",
                  editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                    <FormControl>
                      <Select
                        //   disabled={!rowData.comunidad || rowData.estado=== "CANCELADA" || rowData.estado=== "CERRADA"}
                        value={rowData.estado}
                        onChange={(e) => {
                          const newRowData = { ...rowData, estado: e.target.value };
                          rowData.estado = e.target.value;

                          onRowDataChange(newRowData);
                        }}
                      >
                        {[
                          <MenuItem value="ABIERTO">ABIERTO</MenuItem>,
                          <MenuItem value="BLOQUEADO">BLOQUEADO</MenuItem>,
                        ]}
                      </Select>
                    </FormControl>
                  ),
                },

                //  { title: 'Letra', field: 'letra', defaultFilter: this.filterRef2['letra']},

                {
                  title: "Stock Actual",
                  field: "stock",
                  type: "numeric",
                  defaultFilter: this.filterRef2["stock"],
                  validate: (rowData) =>
                    (rowData.stock !== 0 && !rowData.stock) || rowData.stock < 0
                      ? { isValid: false, helperText: "No puede ser vacío o menor que 0" }
                      : true,
                },
                { title: "Observaciones", field: "observacionesII", defaultFilter: this.filterRef2["observacionesII"] },
              ]}
              detailPanel={[
                {
                  tooltip: "Abrir Detalle",
                  render: (rowData) => {
                    let id_pdf = "Pdf_doc_" + rowData.codigo;
                    var observaciones = rowData.observaciones;
                    observaciones = observaciones.split("\n");
                    return (
                      <div
                        style={{
                          fontSize: 15,
                          padding: 10,
                          background: "#f5da55",
                          color: "primary",
                          backgroundColor: "azure",
                          marginLeft: 30,
                          marginRight: 30,
                        }}
                      >
                        <div
                          id={id_pdf}
                          style={{
                            fontSize: 15,
                            padding: 10,
                            background: "#f5da55",
                            textAlign: "start",
                            color: "primary",
                            backgroundColor: "azure",
                            marginLeft: 30,
                            marginRight: 30,
                            maxWidth: 900,
                            //                                          maxHeight:600
                          }}
                        >
                          <Box fontWeight="fontWeightBold" m={1}>
                            <h3 style={{ color: "red" }}>LOG de Registros</h3>

                            {observaciones.map((valor) => {
                              return <p>{valor}</p>;
                            })}
                          </Box>
                        </div>
                      </div>
                    );
                  },
                },
              ]}
              data={this.state.lista_abonos_luz}
              editable={{
                onRowUpdate: this.state.modificar
                  ? undefined
                  : (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          var user = this.props.firebase.auth.currentUser;

                          let zona = this.state.zona;
                          // write your logic here
                          //    this.setState({lista_final:[]})
                          //   const cambios= Object.values(changes)
                          //     const resul=false//validarCambio(cambios,this.state.lista_final)
                          const lista_nueva = [...this.state.lista_abonos_luz];

                          var ejecuta = true;
                          if (!newData.estado) {
                            alert("Campo Estado no puede ser vacío");
                            ejecuta = false;
                          } else if (!newData.codigo) {
                            alert("Campo Código no puede ser vacío");
                            ejecuta = false;
                          } else if (!newData.stock) {
                            alert("Campo Stock no puede ser vacío");
                            ejecuta = false;
                          } else if (!newData.abonado) {
                            alert("Campo Abonado no puede ser vacío");
                            ejecuta = false;
                          }
                          const index = lista_nueva.findIndex((abono) => abono.dni === oldData.dni);
                          let cambioLog = "";
                          if (newData.estado !== oldData.estado) {
                            cambioLog = "Cambio de estado a " + newData.estado;
                          }
                          if (newData.abonado !== oldData.abonado) {
                            cambioLog = cambioLog + "\nCambio de abonado a " + newData.abonado;
                          }
                          if (newData.codigo !== oldData.codigo) {
                            cambioLog = cambioLog + "\nCambio de código a " + newData.codigo;
                          }
                          if (newData.stock !== oldData.stock) {
                            cambioLog = cambioLog + "\nCambio de stock a " + newData.stock;
                          }

                          const dato = {
                            estado: newData.estado,
                            abonado: newData.abonado,
                            codigo: newData.codigo,
                            dni: newData.dni,
                            stock: newData.stock,
                            observaciones:
                              oldData.observaciones +
                              (cambioLog !== ""
                                ? "\n. " +
                                  moment(new Date()).format("DD-MM-YYYY HH:mm") +
                                  " <" +
                                  user.displayName +
                                  "> " +
                                  cambioLog
                                : ""),
                            observacionesII: newData.observacionesII ? newData.observacionesII : "",
                          };

                          lista_nueva[index] = {};

                          lista_nueva[index] = dato;
                          const esta = lista_nueva.filter((abono) => abono.dni === newData.dni);

                          if (esta && esta.length > 1) {
                            alert("Existe ya un registro abono para este usuario");
                            ejecuta = false;
                          }
                          if (ejecuta) {
                            alert(
                              'La tabla ha sido actualizada correctamente, para guardar los cambios debes "CERRAR" esta ventana  y  escoger "GUARDAR" la transacción, gracias.'
                            );
                            zona.TablaAbonosLuz = lista_nueva;
                            this.setState({ lista_abonos_luz: lista_nueva, zona: zona });
                            resolve();
                          } else {
                            reject();
                          }

                          /* setData([...data, newData]); */
                        }, 1000);
                      }),
                onRowDelete: this.state.modificar
                  ? undefined
                  : (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          let zona = this.state.zona;
                          var lista = this.state.lista_abonos_luz;
                          const index = lista.indexOf(oldData);
                          lista.splice(index, 1);
                          zona.TablaAbonosLuz = lista;
                          this.setState({ lista_abonos_luz: lista, zona: zona });
                          alert(
                            'La tabla ha sido actualizada correctamente, para guardar los cambios debes "CERRAR" esta ventana  y  escoger "GUARDAR" la transacción, gracias.'
                          );

                          resolve();
                        }, 1);
                      }),
                onRowAdd: this.state.modificar
                  ? undefined
                  : (newData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          var user = this.props.firebase.auth.currentUser;
                          var lista = this.state.lista_abonos_luz;
                          let zona = this.state.zona;
                          var ejecuta = true;
                          if (!newData.estado) {
                            alert("Campo Estado no puede ser vacio");
                            ejecuta = false;
                          } else if (!newData.codigo) {
                            alert("Campo Código no puede ser vacio");
                            ejecuta = false;
                          } else if (!newData.stock) {
                            alert("Campo Stock no puede ser vacio");
                            ejecuta = false;
                          } else if (!newData.abonado) {
                            alert("Campo Abonado no puede ser vacio");
                            ejecuta = false;
                          }

                          if (ejecuta) {
                            const dato = {
                              estado: newData.estado,
                              abonado: newData.abonado,
                              codigo: newData.codigo,
                              dni: newData.dni,
                              stock: newData.stock,
                              observaciones:
                                moment(new Date()).format("DD-MM-YYYY HH:mm") +
                                " <" +
                                user.displayName +
                                "> Alta nuevo abono via plataforma web, recarga inicial " +
                                newData.stock,
                              observacionesII: newData.observacionesII ? newData.observacionesII : "",
                            };

                            lista = lista.concat(dato);
                            zona.TablaAbonosLuz = lista;
                            this.setState({ lista_abonos_luz: lista, zona: zona });
                            alert(
                              'La tabla ha sido actualizada correctamente, para guardar los cambios debes "CERRAR" esta ventana  y  escoger "GUARDAR" la transacción, gracias.'
                            );
                            resolve();
                          } else {
                            reject();
                          }
                        }, 1);
                      }),
              }}
            />
          </Paper>

          <Button
            variant="contained"
            onClick={(e) => {
              this.setState({ abrirTablaAbonosLuz: false });
            }}
            color="primary"
            style={{ top: "1%" }}
          >
            Cerrar
          </Button>
        </Paper>
      );
    };

    const { mostrarTabla, left, tipo_zona, horizonte, horarios, horariosFestivos } = this.state;

    const zonas = this.state.zonas;
    const { nombre, descripcion, error, numreservasslot, apertura, cierre } = this.state;
    const isInvalid =
      nombre === "" ||
      descripcion === "" ||
      numreservasslot === "" ||
      !left ||
      apertura === "" ||
      cierre === "" ||
      tipo_zona === "" ||
      !horizonte;
    const vertical = this.state.vertical;
    const horizontal = this.state.horizontal;
    return (
      <Grid>
        <Cabecera />
        <hr />
        <Grid style={{ position: "absolute", right: 20, top: 20, maxWidth: "15%", maxHeight: "15%" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.setState({ mostrarAyuda: true });
            }}
          >
            AYUDA (TUTORIALES)
          </Button>
        </Grid>
        <BotonesTriger />
        <Grid container spacing={0}>
          <ThemeProvider theme={theme}>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              key={`${this.state.vertical},${this.state.horizontal}`}
              open={this.state.snack}
              autoHideDuration={5000}
              onClose={this.handleClose}
              message={this.state.error2}
              action={
                <div>
                  <Button color="primary" size="small" onClick={this.handleClose}>
                    Cerrar
                  </Button>
                  <IconButton aria-label="close" color="inherit" onClick={this.handleClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
              }
            />
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              key={`${this.state.vertical},${this.state.horizontal}`}
              open={this.state.snack2}
              //    autoHideDuration={5000}
              onClose={this.handleClose}
              message={this.state.zonaBorrar}
              action={
                <div>
                  <Button onClick={this.anularZona}>
                    <Fab size="small" color="primary" aria-label="add" variant="extended">
                      <DoneOutlineIcon />
                      CONFIRMAR
                    </Fab>
                  </Button>
                  <Button onClick={this.handleClose}>
                    <Fab size="small" color="secondary" aria-label="add" variant="extended">
                      <CloseIcon />
                      CANCELAR
                    </Fab>
                  </Button>
                </div>
              }
            />
            {!this.state.open && (
              <Grid container direction="row" alignItems="flex-start" spacing={1}>
                <Grid item sm={3.5} style={{ marginLeft: 10 }}>
                  <Button
                    onClick={this.BuscarZonas}
                    variant="outlined"
                    size="large"
                    color="primary"
                    startIcon={<SearchIcon />}
                  >
                    LISTADO DE ZONAS COMUNES
                  </Button>

                  {this.state.añadir ? (
                    <Button onClick={this.añadir}>
                      <Fab size="small" color="primary" aria-label="add" variant="extended">
                        <AddIcon />
                        Añadir
                      </Fab>
                    </Button>
                  ) : (
                    <Button onClick={this.cancelar}>
                      <Fab size="small" color="secondary" aria-label="cancel" variant="extended">
                        <CloseIcon />
                        Cancelar
                      </Fab>
                    </Button>
                  )}

                  {mostrarTabla && (
                    <Paper style={{}}>
                      <List>
                        {zonas &&
                          zonas.map((zona) => (
                            <Grid>
                              <Divider />
                              <ListItem
                                button
                                selected={this.selectedIndex === zona.Nombre}
                                onClick={(event) => this.handleListItemClick(event, zona, "visualizar")}
                              >
                                <ListItemText
                                  style={{ maxWidth: "85%" }}
                                  primary={zona.Nombre}
                                  secondary={zona.Descripcion}
                                />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={
                                      (event) => {
                                        this.setState({
                                          zona: zona,
                                          modificar: true,
                                          snack2: true,
                                          zonaBorrar: "La zona " + zona.Nombre + " será borrada",
                                        });
                                        // alert('¿Deseas borrar la zona?: ' + zona.Nombre )
                                      }
                                      //this.handleListItemClick(event, zona, "delete" )
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                  <IconButton
                                    edge="end"
                                    aria-label="visibility"
                                    onClick={(event) => this.handleListItemClick(event, zona, "visualizar")}
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                  <IconButton
                                    edge="end"
                                    aria-label="borderColor"
                                    onClick={(event) => this.handleListItemClick(event, zona, "modificar")}
                                  >
                                    <BorderColorIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            </Grid>
                          ))}
                      </List>
                    </Paper>
                  )}
                </Grid>
              </Grid>
            )}

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              open={this.state.abrir_cambios}
              onClose={(e) => {
                this.setState({ abrir_cambios: false });
                this.traerZonas2();
              }}
            >
              <Paper style={{ height: "80%", marginTop: "-5%", width: "80%" }}>
                <Paper style={{ padding: 10, maxHeight: "100%", overflowY: "auto" }}>
                  <h3 style={{ color: "white", backgroundColor: "#00a049", marginTop: "-0.5%" }}>
                    Zona: {this.state.nombre}{" "}
                  </h3>
                  <Grid container>
                    <Grid container spacing={1} style={{ marginTop: "0.5%" }}>
                      <Grid item sm={3}>
                        {Textofield2("nombre", "Nombre Zona", this.state.modificar, null, this, "text", null)}
                      </Grid>
                      <Grid item sm={3}>
                        {Textofield2("descripcion", "Descripción", this.state.modificar, null, this, "text", null)}
                      </Grid>
                      <Grid item sm={3}>
                        {selectStandar(this, this.state.tipo_zona, "Tipo de Zona", "tipo_zona", menuZona, null, false)}
                      </Grid>
                      <Grid item sm={3}>
                        {selectStandar(
                          this,
                          this.state.numpersonas,
                          "Número de personas permitidas por reserva",
                          "numpersonas",
                          menuItems20,
                          null,
                          false
                        )}
                      </Grid>
                      <Grid item sm={12}>
                        {selecto(
                          "Roles",
                          "Roles",
                          null,
                          this.state.Roles,
                          this,
                          this.state.modificar,
                          this.state.roles,
                          "Roles que verán quién ha hecho otras reservas"
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ marginTop: "1%" }}>
                      <Grid item sm={3}>
                        {selectStandar(
                          this,
                          this.state.numreservas,
                          "Número de reservas por día/persona",
                          "numreservas",
                          menuItems6,
                          null,
                          false
                        )}
                      </Grid>
                      <Grid item sm={3}>
                        {this.state.limitaciones !== 5 &&
                          selectStandar(
                            this,
                            this.state.horizonte,
                            "Horizonte de Fechas",
                            "horizonte",
                            menuItems30_60_90,
                            null,
                            false
                          )}
                      </Grid>
                      <Grid item sm={3}>
                        {Textofield2("numreservasslot", "Aforo", this.state.modificar, null, this, "number", null)}
                      </Grid>

                      {
                        <Grid item sm={3}>
                          {selectStandar(
                            this,
                            this.state.slots_seguidos,
                            "Slots seguidos permitidos",
                            "slots_seguidos",
                            menuItems6slot,
                            1,
                            this.state.numreservasslot != 1
                          )}
                        </Grid>
                      }
                    </Grid>

                    <Grid container spacing={1} style={{ marginTop: "1%" }}>
                      <Grid item sm={4}>
                        {selectStandar(
                          this,
                          this.state.PermitirReservarHora,
                          "Abrir siguiente día de reservar a partir de las:",
                          "PermitirReservarHora",
                          menuItems24,
                          null,
                          false
                        )}
                      </Grid>
                      <Grid item sm={6}>
                        {selectStandar(
                          this,
                          this.state.limitaciones,
                          "Limitaciones si Reserva Activa",
                          "limitaciones",
                          limitaSiReserva,
                          null,
                          false
                        )}
                      </Grid>
                      <Grid item sm={2}>
                        {this.state.limitaciones == 2 &&
                          selectStandar(
                            this,
                            this.state.LimitacionHora,
                            "Hora de aplicación",
                            "LimitacionHora",
                            menuItems24ap,
                            1,
                            false
                          )}
                        {this.state.limitaciones == 4 &&
                          selectStandar(
                            this,
                            this.state.LimitacionHora,
                            "Horas mínimas de diferencia",
                            "LimitacionHora",
                            menuItems25ap,
                            1,
                            false
                          )}
                        {this.state.limitaciones == 5 &&
                          selectStandar(
                            this,
                            this.state.LimitacionHora,
                            "Horas hasta liberación",
                            "LimitacionHora",
                            menuItems25ap,
                            1,
                            false
                          )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ marginTop: "1%" }}>
                      <Grid item sm={this.state.margenCancelacion ? 3 : 4}>
                        {checkBoxStandar(
                          "mostrarPisoPortalPuerta",
                          "Mostrar Portal, Piso, Letra del que ha reservado",
                          this,
                          this.state.modificar
                        )}
                      </Grid>
                      <Grid item sm={this.state.margenCancelacion ? 3 : 4}>
                        {checkBoxStandar(
                          "NoPermitirSiReservaActiva",
                          "No permitir reservar si Reserva Activa No consumida",
                          this,
                          this.state.modificar
                        )}
                      </Grid>

                      <Grid item sm={this.state.margenCancelacion ? 3 : 4}>
                        {checkBoxStandar(
                          "margenCancelacion",
                          "Margen de cancelación de reserva",
                          this,
                          this.state.modificar
                        )}
                      </Grid>
                      {this.state.margenCancelacion && (
                        <Grid item sm={3}>
                          {Textofield2(
                            "horasCancelacion",
                            "Límite hora cancelación",
                            this.state.modificar,
                            null,
                            this,
                            "number",
                            null
                          )}
                        </Grid>
                      )}
                    </Grid>

                    <h4
                      style={{
                        color: "white",
                        backgroundColor: "#00a049",
                        opacity: 0.7,
                        width: "100%",
                        marginTop: "1.5%",
                      }}
                    >
                      Asignación a comunidad
                    </h4>

                    <Grid container spacing={1}>
                      <Grid item sm={3}>
                        {selectStandar(
                          this,
                          this.state.comunidad,
                          "Comunidad",
                          "comunidad",
                          this.state.menuComunidades,
                          null,
                          false
                        )}
                      </Grid>
                      <Grid item sm={3}>
                        <Button
                          //  disabled = { rowData.estado=== "CANCELADA" || rowData.estado=== "CERRADA"}
                          variant="outlined"
                          color="primary"
                          disableElevation
                          onClick={(e) => {
                            this.gestionZonas(this.state.comus[this.state.comunidad].Id);
                          }}
                        >
                          Historial de Reservas
                        </Button>
                      </Grid>
                      {this.state.RegistroApertura && this.state.MostrarAbrirPuertaEnListado === true && (
                        <Grid item sm={3}>
                          <Button
                            //  disabled = { rowData.estado=== "CANCELADA" || rowData.estado=== "CERRADA"}
                            variant="outlined"
                            color="primary"
                            disableElevation
                            onClick={(e) => {
                              this.mostrarRegistroAcceso(this.state.RegistroApertura);
                            }}
                          >
                            Registro de acceso
                          </Button>
                        </Grid>
                      )}
                    </Grid>

                    <h4
                      style={{
                        color: "white",
                        backgroundColor: "#00a049",
                        opacity: 0.7,
                        width: "100%",
                        marginTop: "1.5%",
                      }}
                    >
                      Control stock de reservas
                    </h4>

                    <Grid container spacing={1} style={{ marginTop: "-1%" }}>
                      <Grid item sm={5}>
                        <FormControlLabel
                          disabled={this.state.modificar}
                          control={
                            <Checkbox
                              checked={this.state.controlStock}
                              color="primary"
                              onChange={(e) => {
                                this.setState({ controlStock: e.target.checked });
                                if (!e.target.checked) {
                                  this.setState({
                                    stockLimite: 1,
                                    tipoControlStock: 1,
                                    NoconsumirStockDia: false,
                                    inicioStock: "",
                                    finStock: "",
                                  });
                                }
                              }}
                            />
                          }
                          label="Control stock reservas"
                        />
                      </Grid>
                    </Grid>
                    {this.state.controlStock && (
                      <>
                        <Grid container spacing={1}>
                          <Grid item sm={4}>
                            {Textofield2(
                              "stockLimite",
                              "Límite reservas/usuario",
                              this.state.modificar,
                              null,
                              this,
                              "number",
                              null
                            )}
                          </Grid>
                          <Grid item sm={4}>
                            {selectStandar(
                              this,
                              this.state.tipoControlStock,
                              "Tipo de control de stock",
                              "tipoControlStock",
                              menuIntervalos,
                              null
                            )}
                          </Grid>
                          <Grid item sm={4}>
                            {this.state.tipoControlStock == 4 && (
                              <Grid container spacing={1}>
                                <Grid item sm={6}>
                                  <InputLabel htmlFor="age-native-simple">Fecha inicio</InputLabel>

                                  <input
                                    value={this.state.inicioStock}
                                    type="date"
                                    name="inicioStock"
                                    defaultValue="03/04/2021"
                                    onChange={(e) => this.onChange(e)}
                                    disabled={this.state.modificar}
                                  />
                                </Grid>
                                <Grid item sm={6} direction="column">
                                  <InputLabel htmlFor="age-native-simple">Fecha fin</InputLabel>

                                  <input
                                    value={this.state.finStock}
                                    type="date"
                                    name="finStock"
                                    onChange={(e) => this.onChange(e)}
                                    //     defaultValue='03/03/2021'
                                    disabled={this.state.modificar}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            {this.state.tipoControlStock == 5 &&
                              selectStandar(
                                this,
                                this.state.Limitaciondias,
                                "Días de limitación",
                                "Limitaciondias",
                                menuItems6dias,
                                1,
                                false
                              )}
                          </Grid>
                        </Grid>

                        <Grid container spacing={1} style={{ marginTop: "1%" }}>
                          <Grid item sm={4}>
                            {checkBoxStandar(
                              "PermitirReservaHoraActual",
                              "Permitir reservar en la hora actual sin tener stock",
                              this,
                              this.state.modificar
                            )}
                          </Grid>
                          <Grid item sm={4}>
                            {checkBoxStandar(
                              "consumirReservaHoraActual",
                              "Consumir del stock reservando en la hora actual",
                              this,
                              this.state.modificar
                            )}
                          </Grid>
                          <Grid item sm={4}>
                            {checkBoxStandar(
                              "consumirDespuesCancelar",
                              "Consumir del stock aunque se cancele la reserva",
                              this,
                              this.state.modificar
                            )}
                          </Grid>
                        </Grid>

                        {!this.state.consumirReservaHoraActual && (
                          <Grid container spacing={1} style={{ marginTop: "1%" }}>
                            <Grid item sm={4}>
                              {checkBoxStandar(
                                "NoconsumirStockDia",
                                "No consumir stock el mismo día de la reserva",
                                this,
                                this.state.modificar
                              )}
                            </Grid>

                            <Grid item sm={4}>
                              {this.state.NoconsumirStockDia &&
                                selectStandar(
                                  this,
                                  this.state.diasAntesSinConsumir,
                                  "No consumir desde el día:",
                                  "diasAntesSinConsumir",
                                  menuItems10,
                                  null,
                                  false
                                )}
                            </Grid>

                            <Grid item sm={4}>
                              {this.state.NoconsumirStockDia &&
                                selectStandar(
                                  this,
                                  this.state.margenSinConsumir,
                                  "No consumir stock a patir de las:",
                                  "margenSinConsumir",
                                  menuItems24,
                                  null,
                                  false
                                )}
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>

                  <h4
                    style={{
                      color: "white",
                      backgroundColor: "#00a049",
                      opacity: 0.7,
                      width: "100%",
                      marginTop: "1.5%",
                    }}
                  >
                    Control de Abonos
                  </h4>

                  <Grid container spacing={1} style={{ marginTop: "-1%" }}>
                    <Grid item sm={2}>
                      <FormControlLabel
                        disabled={this.state.modificar}
                        control={
                          <Checkbox
                            checked={this.state.controlAbonos}
                            color="primary"
                            onChange={(e) => {
                              this.setState({ controlAbonos: e.target.checked });
                              if (!e.target.checked) {
                                this.setState({
                                  stockLimite: 1,
                                  tipoControlStock: 1,
                                  NoconsumirStockDia: false,
                                  inicioStock: "",
                                  finStock: "",
                                });
                              }
                            }}
                          />
                        }
                        label="Control de abonos"
                      />
                    </Grid>

                    {this.state.controlAbonos && (
                      <Grid item sm={3} style={{ textAlign: "center" }}>
                        <Button
                          // disabled ={this.state.modificar}
                          variant="outlined"
                          color="primary"
                          disableElevation
                          onClick={(e) => {
                            const zona = this.state.zona;
                            this.traerUsuarios(zona);
                            this.setState({
                              abrirTablaAbonos: true,
                              lista_abonos: zona.TablaAbonos ? zona.TablaAbonos : [],
                            });
                          }}
                        >
                          TABLA USUARIOS ABONO
                        </Button>
                      </Grid>
                    )}
                    {this.state.controlAbonos && (
                      <Grid item sm={3}>
                        <FormControlLabel
                          disabled={this.state.modificar}
                          control={
                            <Checkbox
                              checked={this.state.pagoAbonoApp}
                              color="primary"
                              onChange={(e) => {
                                this.setState({ pagoAbonoApp: e.target.checked });
                                if (!e.target.checked) {
                                  this.setState({
                                    stockLimite: 1,
                                    tipoControlStock: 1,
                                    NoconsumirStockDia: false,
                                    inicioStock: "",
                                    finStock: "",
                                  });
                                }
                              }}
                            />
                          }
                          label="Recarga de abono app"
                        />
                      </Grid>
                    )}
                    {this.state.controlAbonos && this.state.pagoAbonoApp && (
                      <Grid item sm={3} style={{ textAlign: "left" }}>
                        <Button
                          // disabled ={this.state.modificar}
                          variant="outlined"
                          color="primary"
                          disableElevation
                          onClick={(e) => {
                            const zona = this.state.zona;
                            this.traerUsuarios(zona);
                            this.setState({
                              abrirTablaTipoCobroAbono: true,
                              lista_pagos_abonos: zona.Abonos ? zona.Abonos : [],
                            });
                          }}
                        >
                          TIPOS PAGOS
                        </Button>
                      </Grid>
                    )}
                    {this.state.pagoAbonoApp && (
                      <Grid item sm={2}>
                        <FormControlLabel
                          disabled={this.state.modificar}
                          control={
                            <Checkbox
                              checked={this.state.controlAbonosLuz}
                              color="primary"
                              onChange={(e) => {
                                this.setState({ controlAbonosLuz: e.target.checked });
                                //    if(!e.target.checked){
                                //      this.setState({stockLimite:1,tipoControlStock:1,NoconsumirStockDia:false,
                                //        inicioStock:'',finStock:''})
                                //    }
                              }}
                            />
                          }
                          label="Recarga abono luz"
                        />
                      </Grid>
                    )}
                    {this.state.controlAbonos && this.state.pagoAbonoApp && this.state.controlAbonosLuz && (
                      <>
                        <Grid item sm={3} style={{ textAlign: "center" }}>
                          <Button
                            // disabled ={this.state.modificar}
                            variant="outlined"
                            color="primary"
                            disableElevation
                            onClick={(e) => {
                              const zona = this.state.zona;
                              this.traerUsuarios(zona);
                              this.setState({
                                abrirTablaAbonosLuz: true,
                                lista_abonos_luz: zona.TablaAbonosLuz ? zona.TablaAbonosLuz : [],
                              });
                            }}
                          >
                            USUARIOS ABONO LUZ
                          </Button>
                        </Grid>

                        <Grid item sm={3} style={{ textAlign: "left" }}>
                          <Button
                            // disabled ={this.state.modificar}
                            variant="outlined"
                            color="primary"
                            disableElevation
                            onClick={(e) => {
                              const zona = this.state.zona;
                              this.traerUsuarios(zona);
                              this.setState({
                                abrirTablaTipoCobroAbonoLuz: true,
                                lista_pagos_abonos_luz: zona.AbonosLuz ? zona.AbonosLuz : [],
                              });
                            }}
                          >
                            TIPOS PAGOS LUZ
                          </Button>
                        </Grid>

                        <Grid item sm={3}>
                          <Paper variant="outlined" square>
                            <Grid container sm={12} direction="row">
                              <Grid container sm={6} direction="column">
                                <InputLabel color="green" htmlFor="age-native-simple">
                                  Hora inicio luz
                                </InputLabel>

                                <input
                                  value={this.state.horariosLuzInicio}
                                  type="time"
                                  name="apertura"
                                  list="limittimeslist"
                                  step="1800"
                                  defaultValue="20:00"
                                  onChange={(e) => this.setState({ horariosLuzInicio: e.target.value })}
                                  disabled={this.state.modificar}
                                />
                              </Grid>
                              <datalist id="limittimeslist">
                                <option value="00:00" />
                                <option value="00:30" />
                                <option value="01:00" />
                                <option value="01:30" />
                                <option value="02:00" />
                                <option value="02:30" />
                                <option value="03:00" />
                                <option value="03:30" />
                                <option value="04:00" />
                                <option value="04:30" />
                                <option value="05:00" />
                                <option value="05:30" />
                                <option value="06:00" />
                                <option value="06:30" />
                                <option value="07:00" />
                                <option value="07:30" />
                                <option value="08:00" />
                                <option value="08:30" />
                                <option value="09:00" />
                                <option value="09:30" />
                                <option value="10:00" />
                                <option value="10:30" />
                                <option value="11:00" />
                                <option value="11:30" />
                                <option value="12:00" />
                                <option value="12:30" />
                                <option value="13:00" />
                                <option value="13:30" />
                                <option value="14:00" />
                                <option value="14:30" />
                                <option value="15:00" />
                                <option value="15:30" />
                                <option value="16:00" />
                                <option value="16:30" />
                                <option value="17:00" />
                                <option value="17:30" />
                                <option value="18:00" />
                                <option value="18:30" />
                                <option value="19:00" />
                                <option value="19:30" />
                                <option value="20:00" />
                                <option value="20:30" />
                                <option value="21:00" />
                                <option value="21:30" />
                                <option value="22:00" />
                                <option value="22:30" />
                                <option value="23:00" />
                                <option value="23:30" />
                                <option value="24:00" />
                              </datalist>

                              <Grid container sm={6} direction="column">
                                <InputLabel color="success" htmlFor="age-native-simple">
                                  Hora fin luz
                                </InputLabel>

                                <input
                                  value={this.state.horariosLuzFin === "24:00" ? "00:00" : this.state.horariosLuzFin}
                                  type="time"
                                  name="cierre"
                                  list="limittimeslist"
                                  onChange={(e) => this.setState({ horariosLuzFin: e.target.value })}
                                  defaultValue="18:00"
                                  disabled={this.state.modificar}
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <h4
                    style={{
                      color: "white",
                      backgroundColor: "#00a049",
                      opacity: 0.7,
                      width: "100%",
                      marginTop: "1.5%",
                    }}
                  >
                    Control de Invitados
                  </h4>

                  <Grid container spacing={1} style={{ marginTop: "-1%" }}>
                    <Grid item sm={2}>
                      <FormControlLabel
                        disabled={this.state.modificar}
                        control={
                          <Checkbox
                            checked={this.state.controlPersonasAsistentes}
                            color="primary"
                            onChange={(e) => {
                              this.setState({ controlPersonasAsistentes: e.target.checked });
                              if (!e.target.checked) {
                                this.setState({
                                  stockLimite: 1,
                                  tipoControlStock: 1,
                                  NoconsumirStockDia: false,
                                  inicioStock: "",
                                  finStock: "",
                                });
                              }
                            }}
                          />
                        }
                        label="Control de invitados"
                      />
                    </Grid>

                    {this.state.controlPersonasAsistentes && (
                      <Grid item sm={3}>
                        {Textofield2(
                          "umbralInvitadosSlot",
                          "  Número máximo de invitados por reserva",
                          this.state.modificar,
                          null,
                          this,
                          "number",
                          null
                        )}
                      </Grid>
                    )}
                    {this.state.controlPersonasAsistentes && (
                      <Grid item sm={7}>
                        {Textofield2(
                          "numeroConvivientes",
                          "Número máximo de convivientes no nominales por zona y reserva. Esta opción aplica si el usuario no dispone de convivientes nominales",
                          this.state.modificar,
                          null,
                          this,
                          "number",
                          null
                        )}
                      </Grid>
                    )}
                  </Grid>

                  <h4
                    style={{
                      color: "white",
                      backgroundColor: "#00a049",
                      opacity: 0.7,
                      width: "100%",
                      marginTop: "1.5%",
                    }}
                  >
                    Apertura automática puerta
                  </h4>

                  <Grid container spacing={1} style={{ marginTop: "-1%" }}>
                    <Grid item sm={4}>
                      <FormControlLabel
                        disabled={this.state.modificar}
                        control={
                          <Checkbox
                            checked={this.state.AperturaPuertaActivar}
                            color="primary"
                            onChange={(e) => {
                              this.setState({ AperturaPuertaActivar: e.target.checked });
                            }}
                          />
                        }
                        label="Apertura automática de puerta"
                      />
                    </Grid>

                    {this.state.AperturaPuertaActivar && (
                      <Grid item sm={4}>
                        {Textofield2(
                          "DesdeMinutos",
                          "Activar apertura minutos antes de la hora de reserva",
                          this.state.modificar,
                          null,
                          this,
                          "number",
                          null
                        )}
                      </Grid>
                    )}
                    {this.state.AperturaPuertaActivar && (
                      <Grid item sm={4}>
                        {Textofield2(
                          "DespuesMinutos",
                          "Mantener apertura después de cierre minutos después",
                          this.state.modificar,
                          null,
                          this,
                          "number",
                          null
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <h4
                    style={{
                      color: "white",
                      backgroundColor: "#00a049",
                      opacity: 0.7,
                      width: "100%",
                      marginTop: "1.5%",
                    }}
                  >
                    Gestión horarios
                  </h4>
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item sm={6}>
                      <FormControlLabel
                        disabled={this.state.modificar}
                        control={
                          <Checkbox
                            checked={this.state.horarioFindes}
                            color="primary"
                            onChange={(e) => {
                              this.setState({ horarioFindes: e.target.checked });
                              if (!e.target.checked) {
                                this.setState({ diasFestivos: [], festivos: false, domingos: false, sabados: false });
                              }
                              const horariosFestivos2 =
                                horariosFestivos.lenght > 0
                                  ? horariosFestivos
                                  : [
                                      {
                                        Apertura: "07:00",
                                        Cierre: "18:00",
                                        Tamaño_slot: 1,
                                      },
                                    ];
                              this.setState({ horariosFestivos: horariosFestivos2 });
                            }}
                            name="horarioFindes"
                          />
                        }
                        label="Establecer horario para fines de semana y festivos"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={6}>
                      <Paper elevation={3} variant="outlined" square style={{ marginTop: 10 }}>
                        <Grid container xs={12} justifyContent="flex-start" direction="row">
                          <ButtonGroup size="small" color="primary" variant="text" aria-label="volver">
                            <Button
                              // disabled
                              disabled={this.state.modificar || horarios[horarios.length - 1].Cierre === "00:00"}
                              onClick={() => this.addHorario()}
                            >
                              <Tooltip title="Añadir Horario" aria-label="añadir">
                                <AddCircleOutlineIcon />
                              </Tooltip>
                              AÑADIR HORARIO
                            </Button>
                            <Button
                              disabled={this.state.modificar || horarios.length === 1}
                              onClick={() => this.removeHorario()}
                            >
                              <Tooltip title="Quitar Horario" aria-label="añadir">
                                <RemoveCircleOutlineIcon />
                              </Tooltip>
                              QUITAR HORARIO
                            </Button>
                          </ButtonGroup>
                        </Grid>

                        <List
                          component="nav"
                          dense="true"
                          aria-label="main mailbox folders"
                          justify="space-around"
                          alignItems="center"
                        >
                          {horarios &&
                            horarios.map((horario, index) => (
                              <Paper variant="outlined" square>
                                <hr />

                                <Grid container xs={12} direction="row">
                                  <Grid container xs={6} direction="column">
                                    <InputLabel htmlFor="age-native-simple">Hora de Apertura</InputLabel>

                                    <input
                                      value={horario.Apertura}
                                      type="time"
                                      name="apertura"
                                      list="limittimeslist"
                                      step="1800"
                                      defaultValue="07:00"
                                      onChange={(e) => this.onChangeList(e, horario, index)}
                                      disabled={this.state.modificar}
                                    />
                                  </Grid>
                                  <datalist id="limittimeslist">
                                    <option value="00:00" />
                                    <option value="00:30" />
                                    <option value="01:00" />
                                    <option value="01:30" />
                                    <option value="02:00" />
                                    <option value="02:30" />
                                    <option value="03:00" />
                                    <option value="03:30" />
                                    <option value="04:00" />
                                    <option value="04:30" />
                                    <option value="05:00" />
                                    <option value="05:30" />
                                    <option value="06:00" />
                                    <option value="06:30" />
                                    <option value="07:00" />
                                    <option value="07:30" />
                                    <option value="08:00" />
                                    <option value="08:30" />
                                    <option value="09:00" />
                                    <option value="09:30" />
                                    <option value="10:00" />
                                    <option value="10:30" />
                                    <option value="11:00" />
                                    <option value="11:30" />
                                    <option value="12:00" />
                                    <option value="12:30" />
                                    <option value="13:00" />
                                    <option value="13:30" />
                                    <option value="14:00" />
                                    <option value="14:30" />
                                    <option value="15:00" />
                                    <option value="15:30" />
                                    <option value="16:00" />
                                    <option value="16:30" />
                                    <option value="17:00" />
                                    <option value="17:30" />
                                    <option value="18:00" />
                                    <option value="18:30" />
                                    <option value="19:00" />
                                    <option value="19:30" />
                                    <option value="20:00" />
                                    <option value="20:30" />
                                    <option value="21:00" />
                                    <option value="21:30" />
                                    <option value="22:00" />
                                    <option value="22:30" />
                                    <option value="23:00" />
                                    <option value="23:30" />
                                    <option value="24:00" />
                                  </datalist>

                                  <Grid container xs={6} direction="column">
                                    <InputLabel htmlFor="age-native-simple">Hora de Cierre</InputLabel>

                                    <input
                                      value={horario.Cierre === "24:00" ? "00:00" : horario.Cierre}
                                      type="time"
                                      name="cierre"
                                      list="limittimeslist"
                                      onChange={(e) => this.onChangeList(e, horario, index)}
                                      defaultValue="18:00"
                                      disabled={this.state.modificar}
                                    />
                                  </Grid>
                                </Grid>

                                <hr />
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="age-native-simple">Tamaño del "slot"</InputLabel>
                                  <Select
                                    //  disabled
                                    disabled={this.state.modificar}
                                    value={horario.Tamaño_slot}
                                    onChange={(e) => this.onChangeList(e, horario, index)}
                                    inputProps={{
                                      name: "tamaño_slot",
                                      id: "age-native-simple",
                                    }}
                                  >
                                    <MenuItem value={0.25}>1 cuarto de hora</MenuItem>
                                    <MenuItem value={0.5}>Media hora</MenuItem>
                                    <MenuItem value={0.75}>3 cuartos de hora</MenuItem>
                                    <MenuItem value={1}>1 Hora</MenuItem>
                                    <MenuItem value={1.5}>1 Hora y Media</MenuItem>
                                    <MenuItem value={2}>2 Horas</MenuItem>
                                    <MenuItem value={2.5}>2 Horas y Media</MenuItem>
                                    <MenuItem value={3}>3 Horas</MenuItem>
                                    <MenuItem value={3.5}>3 Horas y Media</MenuItem>
                                    <MenuItem value={4}>4 Horas</MenuItem>
                                    <MenuItem value={4.5}>4 Horas y Media</MenuItem>
                                    <MenuItem value={5}>5 Horas</MenuItem>
                                    <MenuItem value={5.5}>5 Horas y Media</MenuItem>
                                    <MenuItem value={6}>6 Horas</MenuItem>
                                    <MenuItem value={6.5}>6 Horas y Media</MenuItem>
                                    <MenuItem value={7}>7 Horas</MenuItem>
                                    <MenuItem value={7.5}>7 Horas y Media</MenuItem>
                                    <MenuItem value={8}>8 Horas</MenuItem>
                                    <MenuItem value={8.5}>8 Horas y Media</MenuItem>
                                    <MenuItem value={9}>9 Horas</MenuItem>
                                    <MenuItem value={9.5}>9 Horas y Media</MenuItem>
                                    <MenuItem value={10}>10 Horas</MenuItem>
                                    <MenuItem value={10.5}>10 Horas y Media</MenuItem>
                                    <MenuItem value={11}>11 Horas</MenuItem>
                                    <MenuItem value={11.5}>11 Horas y Media</MenuItem>

                                    <MenuItem value={12}>12 Horas</MenuItem>
                                    <MenuItem value={12.5}>12 Horas y Media</MenuItem>
                                    <MenuItem value={13}>13 Horas</MenuItem>
                                    <MenuItem value={13.5}>13 Horas y Media</MenuItem>
                                    <MenuItem value={14}>14 Horas</MenuItem>
                                    <MenuItem value={14.5}>14 Horas y Media</MenuItem>
                                    <MenuItem value={15}>15 Horas</MenuItem>
                                    <MenuItem value={15.5}>15 Horas y Media</MenuItem>
                                    <MenuItem value={16}>16 Horas</MenuItem>
                                    <MenuItem value={16.5}>16 Horas y Media</MenuItem>
                                    <MenuItem value={17}>17 Horas</MenuItem>

                                    <MenuItem value={17.5}>17 Horas y Media</MenuItem>
                                    <MenuItem value={18}>18 Horas</MenuItem>
                                    <MenuItem value={18.5}>18 Horas y Media</MenuItem>
                                    <MenuItem value={19}>19 Horas</MenuItem>
                                    <MenuItem value={19.5}>19 Horas y Media</MenuItem>
                                    <MenuItem value={20}>20 Horas</MenuItem>
                                    <MenuItem value={20.5}>20 Horas y Media</MenuItem>
                                    <MenuItem value={21}>21 Horas</MenuItem>
                                    <MenuItem value={21.5}>21 Horas y Media</MenuItem>
                                    <MenuItem value={22}>22 Horas</MenuItem>

                                    <MenuItem value={22.5}>22 Horas y Media</MenuItem>
                                    <MenuItem value={23}>23 Horas</MenuItem>
                                    <MenuItem value={23.5}>23 Horas y Media</MenuItem>
                                  </Select>
                                </FormControl>
                              </Paper>
                            ))}
                        </List>
                      </Paper>
                    </Grid>

                    <Grid item sm={6}>
                      <Paper>
                        {this.state.horarioFindes && this.state.horarioFindes === true && (
                          <Paper elevation={3} variant="outlined" square style={{ marginTop: 10 }}>
                            <Grid container xs={14} direction="row">
                              <FormControlLabel
                                disabled={this.state.modificar}
                                control={
                                  <Checkbox
                                    checked={this.state.sabados}
                                    color="primary"
                                    onChange={(e) => {
                                      this.setState({ sabados: e.target.checked, hayCambio: true });
                                    }}
                                    name="sabados"
                                  />
                                }
                                label="Sábados"
                              />
                              <hr />
                              <FormControlLabel
                                disabled={this.state.modificar}
                                control={
                                  <Checkbox
                                    checked={this.state.domingos}
                                    color="primary"
                                    onChange={(e) => {
                                      this.setState({ domingos: e.target.checked, hayCambio: true });
                                    }}
                                    name="domingos"
                                  />
                                }
                                label="Domingos"
                              />
                              <hr />
                              <FormControlLabel
                                disabled={this.state.modificar}
                                control={
                                  <Checkbox
                                    checked={this.state.festivos}
                                    color="primary"
                                    onChange={(e) => {
                                      this.setState({ festivos: e.target.checked, hayCambio: true });
                                      if (!e.target.checked) {
                                        this.setState({ diasFestivos: [] });
                                      }
                                    }}
                                    name="festivos"
                                  />
                                }
                                label="Festivos"
                              />
                              {this.state.festivos && (
                                <Button
                                  // disabled
                                  disabled={this.state.modificar}
                                  color="primary"
                                  onClick={() => {
                                    this.setState({ calendario: true });
                                  }}
                                >
                                  <Tooltip title="Añadir o Quitar Días Festivos" aria-label="añadir">
                                    <AddCircleOutlineIcon />
                                  </Tooltip>
                                </Button>
                              )}
                            </Grid>
                            <hr />
                            <Grid container xs={12} justifyContent="flex-end" direction="row">
                              <ButtonGroup size="small" color="primary" variant="text" aria-label="volver">
                                <Button
                                  // disabled
                                  disabled={
                                    this.state.modificar ||
                                    (horariosFestivos &&
                                      horariosFestivos[horariosFestivos.length - 1].Cierre === "00:00")
                                  }
                                  onClick={() => {
                                    var horariosFestivos = this.state.horariosFestivos;
                                    const horario = horariosFestivos[horariosFestivos.length - 1];
                                    var cierre = Number(horario.Cierre.split(":")[0]);
                                    cierre = cierre + 1;
                                    var cierrefinal = cierre + ":" + horario.Cierre.split(":")[1];
                                    if (cierre < 10) {
                                      cierrefinal = "0" + cierrefinal;
                                    } else if (cierre >= 24) {
                                      cierrefinal = "00:00";
                                    }

                                    horariosFestivos = horariosFestivos.concat({
                                      Apertura: horario.Cierre,
                                      Cierre: cierrefinal,
                                      noModificar: true,
                                      Tamaño_slot: 1,
                                    });
                                    this.setState({ horariosFestivos: horariosFestivos, hayCambio: true });
                                  }}
                                >
                                  <Tooltip title="Añadir Horario" aria-label="añadir">
                                    <AddCircleOutlineIcon />
                                  </Tooltip>
                                  AÑADIR HORARIO
                                </Button>
                                <Button
                                  disabled={this.state.modificar || horariosFestivos.length === 1}
                                  onClick={() => {
                                    var horariosFestivos = this.state.horariosFestivos;
                                    horariosFestivos.pop();
                                    this.setState({ horariosFestivos: horariosFestivos, hayCambio: true });
                                  }}
                                >
                                  <Tooltip title="Quitar Horario" aria-label="añadir">
                                    <RemoveCircleOutlineIcon />
                                  </Tooltip>
                                  QUITAR HORARIO
                                </Button>
                              </ButtonGroup>
                            </Grid>

                            <List
                              component="nav"
                              dense="true"
                              aria-label="main mailbox folders"
                              justify="space-around"
                              alignItems="center"
                            >
                              {horariosFestivos &&
                                horariosFestivos.map((horario, index) => (
                                  <Paper variant="outlined" square>
                                    <hr />

                                    <Grid container xs={12} direction="row">
                                      <Grid container xs={6} direction="column">
                                        <InputLabel htmlFor="age-native-simple">Hora de Apertura</InputLabel>

                                        <input
                                          value={horario.Apertura}
                                          type="time"
                                          name="apertura_festivo"
                                          list="limittimeslist"
                                          step="1800"
                                          defaultValue="07:00"
                                          onChange={(e) => this.onChangeListFestivos(e, horario, index)}
                                          disabled={this.state.modificar} //|| horario.noModificar}
                                        />
                                      </Grid>
                                      <datalist id="limittimeslist">
                                        <option value="00:00" />
                                        <option value="00:30" />
                                        <option value="01:00" />
                                        <option value="01:30" />
                                        <option value="02:00" />
                                        <option value="02:30" />
                                        <option value="03:00" />
                                        <option value="03:30" />
                                        <option value="04:00" />
                                        <option value="04:30" />
                                        <option value="05:00" />
                                        <option value="05:30" />
                                        <option value="06:00" />
                                        <option value="06:30" />
                                        <option value="07:00" />
                                        <option value="07:30" />
                                        <option value="08:00" />
                                        <option value="08:30" />
                                        <option value="09:00" />
                                        <option value="09:30" />
                                        <option value="10:00" />
                                        <option value="10:30" />
                                        <option value="11:00" />
                                        <option value="11:30" />
                                        <option value="12:00" />
                                        <option value="12:30" />
                                        <option value="13:00" />
                                        <option value="13:30" />
                                        <option value="14:00" />
                                        <option value="14:30" />
                                        <option value="15:00" />
                                        <option value="15:30" />
                                        <option value="16:00" />
                                        <option value="16:30" />
                                        <option value="17:00" />
                                        <option value="17:30" />
                                        <option value="18:00" />
                                        <option value="18:30" />
                                        <option value="19:00" />
                                        <option value="19:30" />
                                        <option value="20:00" />
                                        <option value="20:30" />
                                        <option value="21:00" />
                                        <option value="21:30" />
                                        <option value="22:00" />
                                        <option value="22:30" />
                                        <option value="23:00" />
                                        <option value="23:30" />
                                        <option value="24:00" />
                                      </datalist>

                                      <Grid container xs={6} direction="column">
                                        <InputLabel htmlFor="age-native-simple">Hora de Cierre</InputLabel>

                                        <input
                                          value={horario.Cierre === "24:00" ? "00:00" : horario.Cierre}
                                          type="time"
                                          name="cierre_festivo"
                                          list="limittimeslist"
                                          onChange={(e) => this.onChangeListFestivos(e, horario, index)}
                                          defaultValue="18:00"
                                          disabled={this.state.modificar}
                                        />
                                      </Grid>
                                    </Grid>

                                    <hr />
                                    <FormControl fullWidth>
                                      <InputLabel htmlFor="age-native-simple">Tamaño del "slot"</InputLabel>
                                      <Select
                                        //  disabled
                                        disabled={this.state.modificar}
                                        value={horario.Tamaño_slot}
                                        onChange={(e) => this.onChangeListFestivos(e, horario, index)}
                                        inputProps={{
                                          name: "tamaño_slot_festivo",
                                          id: "age-native-simple",
                                        }}
                                      >
                                        <MenuItem value={0.25}>1 cuarto de hora</MenuItem>
                                        <MenuItem value={0.5}>Media hora</MenuItem>
                                        <MenuItem value={0.75}>3 cuartos de hora</MenuItem>
                                        <MenuItem value={1}>1 Hora</MenuItem>
                                        <MenuItem value={1.5}>1 Hora y Media</MenuItem>
                                        <MenuItem value={2}>2 Horas</MenuItem>
                                        <MenuItem value={2.5}>2 Horas y Media</MenuItem>
                                        <MenuItem value={3}>3 Horas</MenuItem>
                                        <MenuItem value={3.5}>3 Horas y Media</MenuItem>
                                        <MenuItem value={4}>4 Horas</MenuItem>
                                        <MenuItem value={4.5}>4 Horas y Media</MenuItem>
                                        <MenuItem value={5}>5 Horas</MenuItem>
                                        <MenuItem value={5.5}>5 Horas y Media</MenuItem>
                                        <MenuItem value={6}>6 Horas</MenuItem>
                                        <MenuItem value={6.5}>6 Horas y Media</MenuItem>
                                        <MenuItem value={7}>7 Horas</MenuItem>
                                        <MenuItem value={7.5}>7 Horas y Media</MenuItem>
                                        <MenuItem value={8}>8 Horas</MenuItem>
                                        <MenuItem value={8.5}>8 Horas y Media</MenuItem>
                                        <MenuItem value={9}>9 Horas</MenuItem>
                                        <MenuItem value={9.5}>9 Horas y Media</MenuItem>
                                        <MenuItem value={10}>10 Horas</MenuItem>
                                        <MenuItem value={10.5}>10 Horas y Media</MenuItem>
                                        <MenuItem value={11}>11 Horas</MenuItem>
                                        <MenuItem value={11.5}>11 Horas y Media</MenuItem>
                                        <MenuItem value={12}>12 Horas</MenuItem>

                                        <MenuItem value={12.5}>12 Horas y Media</MenuItem>
                                        <MenuItem value={13}>13 Horas</MenuItem>
                                        <MenuItem value={13.5}>13 Horas y Media</MenuItem>
                                        <MenuItem value={14}>14 Horas</MenuItem>
                                        <MenuItem value={14.5}>14 Horas y Media</MenuItem>
                                        <MenuItem value={15}>15 Horas</MenuItem>
                                        <MenuItem value={15.5}>15 Horas y Media</MenuItem>
                                        <MenuItem value={16}>16 Horas</MenuItem>
                                        <MenuItem value={16.5}>16 Horas y Media</MenuItem>
                                        <MenuItem value={17}>17 Horas</MenuItem>

                                        <MenuItem value={17.5}>17 Horas y Media</MenuItem>
                                        <MenuItem value={18}>18 Horas</MenuItem>
                                        <MenuItem value={18.5}>18 Horas y Media</MenuItem>
                                        <MenuItem value={19}>19 Horas</MenuItem>
                                        <MenuItem value={19.5}>19 Horas y Media</MenuItem>
                                        <MenuItem value={20}>20 Horas</MenuItem>
                                        <MenuItem value={20.5}>20 Horas y Media</MenuItem>
                                        <MenuItem value={21}>21 Horas</MenuItem>
                                        <MenuItem value={21.5}>21 Horas y Media</MenuItem>
                                        <MenuItem value={22}>22 Horas</MenuItem>

                                        <MenuItem value={22.5}>22 Horas y Media</MenuItem>
                                        <MenuItem value={23}>23 Horas</MenuItem>
                                        <MenuItem value={23.5}>23 Horas y Media</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Paper>
                                ))}
                            </List>
                          </Paper>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>

                {this.state.modificar && (
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      this.setState({ modificar: false, tipo: "guardar" });
                    }}
                    color="primary"
                    style={{ top: "1%" }}
                  >
                    Editar
                  </Button>
                )}
                {!this.state.modificar && (
                  <>
                    <Button
                      variant="contained"
                      style={{ top: "1%", backgroundColor: "orange" }}
                      onClick={(e) => {
                        this.traerZonas2();
                        this.setState({ abrir_cambios: false, modificar: true });
                      }}
                      color="primary"
                    >
                      Cancelar
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ top: "1%", left: 5 }}
                      disabled={this.state.modificar || this.state.nombre === "" || this.state.tipo_zona === ""}
                      onClick={(e) => {
                        this.onSubmit();
                      }}
                    >
                      Guardar
                    </Button>
                  </>
                )}
              </Paper>
            </Modal>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              open={this.state.calendario}
              onClose={(e) => this.setState({ calendario: false })}
            >
              <Paper
                style={{
                  maxHeight: 500,
                  maxWidth: "30%",
                  alignContent: "center",
                  alignItems: "center",
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 20,
                }}
              >
                <div className="Sample">
                  <header>
                    <h2 style={{ marginLeft: 10, color: "green" }}>CALENDARIO DÍAS FESTIVOS </h2>
                    <h3 style={{ marginLeft: 10, color: "green" }}>Marca los días que serán festivos</h3>
                  </header>

                  <Calendar
                    style={{ marginLeft: 10 }}
                    //   onChange={(value) =>

                    //     alert('Clicked melon: '+ value)}
                    onClickDay={(value, event) => {
                      var diasFestivos = this.state.diasFestivos;
                      var entra = true;
                      diasFestivos &&
                        diasFestivos.forEach((fecha, indice) => {
                          if (moment(fecha).format("DD-MM-YYYY") === moment(value).format("DD-MM-YYYY")) {
                            diasFestivos.splice(indice, 1);
                            entra = false;
                          }
                        });
                      if (entra) {
                        diasFestivos = diasFestivos.concat(value);
                      }

                      this.setState({ diasFestivos: diasFestivos, hayCambio: true });
                    }}
                    tileClassName={({ date, view }) => {
                      const diasFestivos = this.state.diasFestivos;
                      if (diasFestivos && diasFestivos.length > 0) {
                        if (
                          diasFestivos.find(
                            (fecha) => moment(fecha).format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY")
                          )
                        ) {
                          return "content";
                        }
                      }
                    }}
                    /*            tileClassName={({date, view}) => {
                                const fechaCalendario =this.state.fechaCalendario
                                if(fechaCalendario&&fechaCalendario.length>0){

                                  fechaCalendario.map(fecha =>{
                                      if(moment(fecha).format('YYYYMMDD')===moment(date).format('YYYYMMDD')) {
                                        return "content"}})
                                }
                              
                              }}*/
                  />
                </div>
              </Paper>
            </Modal>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              open={this.state.mostrarAyuda}
              onClose={(e) => this.setState({ mostrarAyuda: false })}
            >
              {this.state.mostrarAyuda && <ModalTutoriales Pdfs={this.state.VideoTuto} />}
            </Modal>

            {this.state.abrirTablaAbonos && (
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                open={this.state.abrirTablaAbonos}
                onClose={(e) => {
                  this.setState({ abrirTablaAbonos: false });
                }}
              >
                {TablaAbonos()}
              </Modal>
            )}

            {this.state.abrirTablaAbonosLuz && (
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                open={this.state.abrirTablaAbonosLuz}
                onClose={(e) => {
                  this.setState({ abrirTablaAbonosLuz: false });
                }}
              >
                {TablaAbonosLuz()}
              </Modal>
            )}

            {this.state.abrirTablaTipoCobroAbono && (
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                open={this.state.abrirTablaTipoCobroAbono}
                onClose={(e) => {
                  this.setState({ abrirTablaTipoCobroAbono: false });
                }}
              >
                {TablaTipoCobroAbono()}
              </Modal>
            )}

            {this.state.abrirTablaTipoCobroAbonoLuz && (
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                open={this.state.abrirTablaTipoCobroAbonoLuz}
                onClose={(e) => {
                  this.setState({ abrirTablaTipoCobroAbonoLuz: false });
                }}
              >
                {TablaTipoCobroAbonoLuz()}
              </Modal>
            )}

            <Backdrop color="green" open={this.state.open}>
              <CircularProgress color="green" />
            </Backdrop>
          </ThemeProvider>
        </Grid>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={this.state.abrir_zonas}
          onClose={(e) => {
            this.setState({
              abrir_zonas: false,
              listaZonasRecuperadasFiltrada: [],
              listaZonasRecuperadas: [],
              añoActual: [],
              años: [],
              abrir_cambios: true,
            });
          }}
        >
          <Paper
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              margin: 20,
              maxHeight: 500,
              overflow: "auto",
              paddingTop: 10,

              listStyle: "none",
            }}
          >
            <MaterialTable
              options={{
                doubleHorizontalScroll: true,
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                filtering: true,

                grouping: true,
                exportDelimiter: ";",
                exportAllData: true,
                maxBodyHeight: 600,
                pageSize: 20,
                pageSizeOptions: [10, 20, 30],
                headerStyle: {
                  backgroundColor: "#00a049",
                  color: "white",
                },
                paddingTop: 5,
                paddingBottom: 5,
                width: "100%",
              }}
              icons={tableIcons}
              title={
                <>
                  <h3 style={{ color: "#00a049" }}>RESUMEN RESERVAS ZONA, {this.state.nombreZona}</h3>
                  <Grid container spacing={1}>
                    <Grid item sm={10}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink htmlFor="select-multiple-native">
                          Listado para el año
                        </InputLabel>

                        <Select
                          value={this.state.añoActual}
                          // disabled={bloquear}
                          onChange={(e) => {
                            this.setState({ añoActual: e.target.value });
                            const lista = this.state.listaZonasRecuperadas;
                            const nuevaLista = lista.filter((zon) => zon.Año === e.target.value);

                            this.setState({ listaZonasRecuperadasFiltrada: nuevaLista });

                            //                                          newRowData.Seguro =melon[0].uid
                          }}
                        >
                          {this.state.años &&
                            this.state.años.map((año) => <MenuItem value={año.Nombre}>{año.Nombre}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              }
              columns={[
                {
                  title: "Estado",
                  field: "Estado",
                  cellStyle: {
                    width: 10,
                    minWidth: 10,
                  },
                  headerStyle: {
                    width: 10,
                    minWidth: 10,
                  },
                },

                {
                  title: "Fecha",
                  field: "Fecha",
                  cellStyle: {
                    width: 10,
                    minWidth: 10,
                  },
                  headerStyle: {
                    width: 10,
                    minWidth: 10,
                  },
                },
                { title: "Hora Inicio", field: "Hora" },
                { title: "Hora Fin", field: "HoraFin" },
                { title: "Usuario", field: "Usuario" },
                { title: "Dirección", field: "Direccion" },
                { title: "Portal", field: "Portal" },
                { title: "Número", field: "Nmero" },
                { title: "Piso", field: "Piso" },
                { title: "Letra", field: "Letra" },

                { title: "DNI", field: "DNI" },

                { title: "Personas incluidas", field: "Personas" },
              ]}
              data={this.state.listaZonasRecuperadasFiltrada}
            />
          </Paper>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={this.state.openRegistroAcceso}
          onClose={(e) => {
            this.setState({
              openRegistroAcceso: false,
              abrir_zonas: false,
              listaZonasRecuperadasFiltrada: [],
              listaZonasRecuperadas: [],
              añoActual: [],
              años: [],
              abrir_cambios: true,
            });
          }}
        >
          <Paper
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              margin: 20,
              maxHeight: 500,
              overflow: "auto",
              paddingTop: 10,

              listStyle: "none",
            }}
          >
            <MaterialTable
              options={{
                doubleHorizontalScroll: true,
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                filtering: true,

                grouping: true,
                exportDelimiter: ";",
                exportAllData: true,
                maxBodyHeight: 600,
                pageSize: 20,
                pageSizeOptions: [10, 20, 30],
                headerStyle: {
                  backgroundColor: "#00a049",
                  color: "white",
                },
                paddingTop: 5,
                paddingBottom: 5,
                width: "90%",
              }}
              icons={tableIcons}
              title={
                <>
                  <h3 style={{ color: "#00a049" }}>
                    REGISTRO DE ACCESO, {this.state.nombre && this.state.nombre.toUpperCase()}
                  </h3>
                  <Grid container spacing={1}>
                    <Grid item sm={10}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink htmlFor="select-multiple-native">
                          Listado para el año
                        </InputLabel>

                        <Select
                          value={this.state.añoActual}
                          // disabled={bloquear}
                          onChange={(e) => {
                            this.setState({ añoActual: e.target.value });
                            const lista = this.state.registrosAccesos;
                            let nuevaLista = lista;
                            if (e.target.value !== "#") {
                              nuevaLista = lista.filter(
                                (zon) => new Date(zon.fechaApertura).getFullYear() === e.target.value
                              );
                            }

                            this.setState({ registrosAccesosFiltrados: nuevaLista });
                            // newRowData.Seguro =melon[0].uid
                          }}
                        >
                          <MenuItem value={"#"}>{"Todos"}</MenuItem>
                          {this.state.añosAccesos &&
                            this.state.añosAccesos.map((año) => <MenuItem value={año}>{año}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              }
              columns={[
                {
                  title: "Fecha",
                  field: "fechaAperturaFormateada",
                  cellStyle: {
                    width: 10,
                    minWidth: 10,
                  },
                  headerStyle: {
                    width: 10,
                    minWidth: 10,
                  },
                  customSort: (a, b) => a.fechaApertura - b.fechaApertura,
                  /*
                  render: (rowData) =>
                    rowData.fechaApertura
                      ? new Date(rowData.fechaApertura).toLocaleString("default", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })
                      : "Sin fecha",
                  export: false 
                  */
                },
                /*
                {
                  title: "FechaFormateada",
                  field: "fechaAperturaFormateada",
                  cellStyle: {
                    width: 10,
                    minWidth: 10,
                  },
                  headerStyle: {
                    width: 10,
                    minWidth: 10,
                  },
                  render: (rowData) =>
                    rowData.fechaApertura
                      ? new Date(rowData.fechaApertura).toLocaleString("default", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })
                      : "Sin fecha",
                  hidden: true ,
                  export: true    
                },*/

                {
                  title: "Nombre",
                  field: "nombre",
                  cellStyle: {
                    width: 10,
                    minWidth: 10,
                  },
                  headerStyle: {
                    width: 10,
                    minWidth: 10,
                  },
                },
                { title: "DNI", field: "uid" },
                { title: "Email", field: "email" },
              ]}
              data={this.state.registrosAccesosFiltrados}
            />
          </Paper>
        </Modal>

        <Box mt={40}>
          <Copyright />
        </Box>
      </Grid>
    );
  }
}

const condition = (authUser) => !!authUser;
export default withFirebase(withAuthorization(condition)(Zonas));
