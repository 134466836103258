import React, { forwardRef, Component } from "react";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import {
  FormGroup,
  Switch,
  FormLabel,
  Snackbar,
  Paper,
  IconButton,
  TextField,
  FormControlLabel,
  Box,
  Backdrop,
  CircularProgress,
  Modal,
  Fab,
  Divider,
  ListItem,
  List,
  Grid,
  Button,
  ListItemText,
} from "@material-ui/core";

import moment from "moment";
import SaveIcon from "@material-ui/icons/Save";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

import BotonesTriger from "../Botones/botonTrige";
import Cabecera from "../Cabecera/index_365";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import green from "@material-ui/core/colors/green";
import AddBox from "@material-ui/icons/AddBox";

import {
  Edit,
  DeleteOutline,
  Clear,
  ChevronRight,
  ChevronLeft,
  ArrowDownward,
  FilterList,
  Check,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";

import MaterialTable from "material-table";

import Copyright from "../Privacidad/copyright";
import ModalTutoriales, { traerTutoriales } from "../Tutoriales/tutoriales";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
      claro: green[200],
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#e81446",
    },
  },
});

class Sesiones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mostrarAyuda: false,
      entroPrimeravez: true,
      carpetas: [],
      Pdfs: [],
      jitsi: "",
      dentro: false,
      despacho: [],
      openSesion: false,
      votaciones: [],
      usuariosSesion: [],
      uid: "",
      fecha_publicacion: "",
      añadir: true,
      incis: [],
      identIncial: "",
      comus: [],
      open: false,
      comunidad: "",
      tipo: "guardar",
      votacion: "",
      mostrarTabla: false,
      jitsiContainerId: "jitsi-container-id",
      email: "",
      password: "",
      error: null,
      error2: null,
      modificar: true,
      sis: "",
      nos: "",
      abstenciones: "",
      total: "",
      listaComunidades: [],
      ComunidadesAsig: [],
      ComunidadesNoAsig: [],
      tenemosLista: false,
      label_publicar: "SIN PUBLICAR",
      label_abrir: "CERRADA",
      usuariosComu: [],
      usuarios: [],
      snack: false,
      snack2: false,
      vertical: "top",
      horizontal: "center",
      publicar: false,
      abrir: false,
    };

    this.borrarFiltros = this.borrarFiltros.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.buscarComunidades = this.buscarComunidades.bind(this);
    this.cerrrarVideoCall = this.cerrrarVideoCall.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.buscarUsuarios = this.buscarUsuarios.bind(this);
  }

  handleClose() {
    this.setState({ snack: false, snack2: false });
  }

  componentDidMount() {
    this.buscarComunidades();
    this.buscarUsuarios();
    this.buscarDespacho();

    //traemos manuales
    traerTutoriales(this, "Sesiones").then((data) => {
      this.setState({ Pdfs: data });
    });
  }
  buscarUsuarios() {
    this.props.firebase.users().on("value", (snapshot) => {
      const usersObject = snapshot.val();
      if (usersObject) {
        const lista = Object.keys(usersObject).map((key) => ({
          ...usersObject[key],
          uid: key,
        }));
        this.setState({ usuarios: lista });
      } else {
        this.setState({ usuarios: [] });
      }
    });
  }
  loadJitsiScript = () => {
    let resolveLoadJitsiScriptPromise = null;
    const loadJitsiScriptPromise = new Promise((resolve) => {
      resolveLoadJitsiScriptPromise = resolve;
    });

    const script = document.createElement("script");

    var servidor = "c365juntas.comunidad365.es/";

    if (this.state.despacho.ServidorVideo && this.state.despacho.ServidorVideo !== "") {
      servidor = this.state.despacho.ServidorVideo;
    }

    script.src = "https://" + servidor + "/external_api.js#disableAEC:false";

    script.async = true;
    script.onload = () => resolveLoadJitsiScriptPromise(true);

    document.body.appendChild(script);

    return loadJitsiScriptPromise;
  };

  initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await this.loadJitsiScript();
    }
    const despacho = this.state.despacho;
    const comunidad = this.state.comunidad.uid;
    const roomName = comunidad + despacho.Codigo + comunidad;
    try {
      const options99 = {
        configOverwrite: {
          enableWelcomePage: true,
          defaultLanguage: "es",
          fileRecordingsEnabled: true,
          liveStreamingEnabled: true,
          startWithAudioMuted: false,
          startWithVideoMuted: false,
        },
        interfaceConfigOverwrite: {
          DEFAULT_BACKGROUND: "#1AC570",
          DEFAULT_LOCAL_DISPLAY_NAME: despacho.Nombre,
          TOOLBAR_BUTTONS: [
            "microphone",
            "camera",
            "closedcaptions",
            "desktop",
            "fullscreen",
            "fodeviceselection",
            "profile",
            "chat",
            "recording",
            "livestreaming",
            "etherpad",
            "sharedvideo",
            "raisehand",
            "filmstrip",
            "feedback",
            "stats",
            "shortcuts",
            "tileview",
            "hangup",
            "videoquality",
            "settings",
            "help",
            "invite",
            "stats",
            "videobackgroundblur",
            "download",
            "mute-everyone",
            "e2ee",
            "security",
          ],

          SETTINGS_SECTIONS: ["devices", "language", "moderator", "profile", "calendar"],
        },
        parentNode: document.getElementById(this.state.jitsiContainerId),
        roomName: roomName,

        jwt: this.state.despacho.TokenJitsi ? this.state.despacho.TokenJitsi : null,
        userInfo: {
          email: despacho.Correo,
          displayName: despacho.Nombre,
        },
        width: 700,
        height: 400,
      };

      const options = {
        configOverwrite: {
          enableWelcomePage: true,
          defaultLanguage: "es",
          fileRecordingsEnabled: true,
          liveStreamingEnabled: true,
          startWithAudioMuted: false,
          startWithVideoMuted: false,
        },
        interfaceConfigOverwrite: {
          DEFAULT_BACKGROUND: "#1AC570",
          DEFAULT_LOCAL_DISPLAY_NAME: despacho.Nombre,
          TOOLBAR_BUTTONS: [
            "microphone",
            "camera",
            "closedcaptions",
            "desktop",
            "fullscreen",
            "fodeviceselection",
            "profile",
            "chat",
            "recording",
            "livestreaming",
            "etherpad",
            "sharedvideo",
            "raisehand",
            "filmstrip",
            "feedback",
            "stats",
            "shortcuts",
            "tileview",
            "hangup",
            "videoquality",
            "settings",
            "help",
            "invite",
            "stats",
            "videobackgroundblur",
            "download",
            "mute-everyone",
            "e2ee",
            "security",
          ],

          SETTINGS_SECTIONS: ["devices", "language", "moderator", "profile", "calendar"],
        },
        parentNode: document.getElementById("jitsi-container-id"),
        roomName: roomName,

        jwt: despacho.TokenJitsi ? despacho.TokenJitsi : null,
        userInfo: {
          email: despacho.Correo,
          displayName: despacho.Nombre,
        },
        width: "100%",
        height: "100%",
      };

      var servidor = "meet.comunidad365.es";
      if (this.state.despacho.ServidorVideo && this.state.despacho.ServidorVideo !== "") {
        servidor = this.state.despacho.ServidorVideo;
      }

      const _jitsi = new window.JitsiMeetExternalAPI(servidor, options);


      this.setState({ jitsi: _jitsi });
      this.setState({ openSesion: false, label_abrir: "ABIERTA" });

      _jitsi.once("videoConferenceJoined", (response) => {
        //  if(this.state.entroPrimeravez){ //Es la primer vez aque  entramos, sacamos  a cualquier que esté
        //     const participantes = _jitsi.getParticipantsInfo();
        //     participantes.map((parti)=>{
        //       if(response.id!== parti.participantId){
        //         this.state.jitsi.executeCommand('kickParticipant',parti.participantId);
        //       }
        //     })

        //     this.setState({entroPrimeravez:false})
        //    }

        _jitsi.executeCommand("setFollowMe", true);

        const comu = this.state.comunidad;
        const comusList = this.state.comus;
        if (comu && comu !== "") {
          const comunidad = comusList.filter((com) => com.Id === comu.Id);
          const Junta = comunidad[0].Junta;

          if (!Junta.Estado) {
            this.props.firebase.comu(this.state.comunidad.uid + "/Junta").update({
              Estado: true,
            });
          }
          this.setState({ dentro: true });
        }
      });
      _jitsi.once("videoConferenceLeft", (response) => {
        //      _jitsi.dispose()
        //      this.state.jitsi.dispose()
        //    this.setState({dentro:false})
      });
      _jitsi.on("participantJoined", (response) => {
        //  const email =  _jitsi.getEmail(response.id);
        var email = response.displayName;

        var usuariosSesion = this.state.usuariosSesion;

        //Primero vemos  si este usuario ya se había conectado
        const indexUsuario = usuariosSesion.findIndex((us) => us.Email === email);

        if (indexUsuario > -1) {
          const fecha = new Date();
          usuariosSesion[indexUsuario].idCall = response.id;
          usuariosSesion[indexUsuario].Conexiones = Number(usuariosSesion[indexUsuario].Conexiones) + 1;

          usuariosSesion[indexUsuario].Entrada = moment(fecha).format("HH:mm:ss");
        } else {
          const usuario = this.state.usuariosComu.find((usu) => usu.Email === email);
          var usu = {};
          if (usuario) {
            const fecha = new Date();
            usu = {
              ...usuario,
              idCall: response.id,
              Salida: "",
              Tiempo: 0,
              Entrada: moment(fecha).format("HH:mm:ss"),
              Conexiones: 1,
            };
          } else {
            const fecha = new Date();
            usu = {
              Nombre: "Usuario no encontrado como vecino de la comunidad",
              idCall: response.id,
              Salida: "",
              Tiempo: "",
              Email: "",
              DNI: "",
              Entrada: moment(fecha).format("HH:mm:ss"),
              Conexiones: 1,
            };
          }
          usuariosSesion = usuariosSesion.concat(usu);
        }

        this.setState({ usuariosSesion: usuariosSesion });
      });
      _jitsi.on("participantLeft", (response) => {
        var usuariosSesion = this.state.usuariosSesion;

        const indexUsuario = usuariosSesion.findIndex((us) => us.idCall === response.id);

        if (indexUsuario > -1) {
          const fecha = new Date();
          var lunch = moment(usuariosSesion[indexUsuario].Entrada, "HH:mm:ss");

          const Tiempo = moment().diff(lunch, "minutes");

          const tiempoAcumulado = Number(Tiempo) + Number(usuariosSesion[indexUsuario].Tiempo);
          usuariosSesion[indexUsuario].Tiempo = tiempoAcumulado;
          usuariosSesion[indexUsuario].Salida = moment(fecha).format("HH:mm:ss");
        }
        this.setState({ usuariosSesion: usuariosSesion });
      });
    } catch (error) {
      console.log("Error:",error);
    }
  };

  cerrrarVideoCall() {
    var usuariosSesion = this.state.usuariosSesion;

    usuariosSesion.forEach((usu) => {
      if (!usu.Salida) {
        const fecha = new Date();
        var tiempo = moment(fecha, "HH:mm");
        var lunch = moment(usu.Entrada, "HH:mm");
        usu.Tiempo = tiempo.diff(lunch, "hours", true).toString().slice(0, 4);
        usu.Salida = moment(fecha).format("HH:mm:ss");
      }
    });
    this.props.firebase.comu(this.state.comunidad.uid + "/Junta").update({
      Estado: false,
    });

    this.setState({ usuariosSesion: usuariosSesion });
  }
  buscarComunidades() {
    this.setState({ open: true, mostrarTabla: false, añadir: true, tenemosLista: false });

    this.props.firebase.comus().on("value", (snapshot) => {
      const usersObject = snapshot.val();
      if (usersObject) {
        const comusList = Object.keys(usersObject).map((key) => ({
          ...usersObject[key],
          uid: key,
        }));
        this.setState({ comus: comusList });
        this.setState({ mostrarTabla: true, open: false });
        //comprbamos si tenemso nuestra sesión aierta en el pc y si es así ha que cerrarla porque un usuario admin
        //la ha cerrado
        const comu = this.state.comunidad;
        if (comu && comu !== "") {
          const comunidad = comusList.filter((com) => com.Id === comu.Id);
          const Junta = comunidad[0].Junta;

          if (this.state.dentro && !Junta.Estado) {
            this.state.jitsi.dispose();
            this.cerrrarVideoCall();
            this.setState({ abrir: false, usuariosSesion: [] });
            this.setState({ label_abrir: "CERRADA", dentro: false });
            //Revisar porque no cambia el estado de Abrir a false y entra siempre aunque no se cierre desde el movil
            //  alert("Usuario administrador ha cerrado la call desde el móvil ")
          }
          if (Junta.Estado) {
            this.setState({ abrir: true, label_abrir: "ABIERTA" });
          } else {
            this.setState({ abrir: false, usuariosSesion: [], label_abrir: "CERRADA" });
          }
        }
      } else {
        this.setState({ comus: [] });
        this.setState({ mostrarTabla: true, open: false });
      }
    });
  }
  buscarDespacho() {
    this.props.firebase.despacho().once("value", (snapshot) => {
      const usersObject = snapshot.val();
      this.setState({ despacho: usersObject });
    });
  }

  componentWillUnmount() {
    const comunidad = this.state.comunidad;
    this.props.firebase.votaciones().off();
    this.props.firebase.comus().off();
    this.props.firebase.users().off();
    if (this.state.dentro) {
      this.state.jitsi.dispose();
    }
    if (comunidad.uid) {
      this.props.firebase.comu(comunidad.uid + "/Junta").update({
        Estado: false,
      });
    }
  }

  borrarFiltros() {
    this.setState({ votaciones: [], nombre: "", descripcion: "", tenemosLista: false });
  }

  handleListItemClick(event, comunidad) {
    if (this.state.abrir) {
      if (this.state.dentro) {
        this.state.jitsi.dispose();
      }

      this.cerrrarVideoCall();
      alert("Se ha cerrado la junta virtual abierta de la comunidad " + this.state.identIncial);
      this.setState({ abrir: false, usuariosSesion: [] });
    }

    const usuariosComu = this.state.usuarios.filter((usuario) => usuario.Comunidad === comunidad.Id);
    this.setState({ comunidad: comunidad, identIncial: comunidad.Nombre, usuariosComu: usuariosComu });
    const Junta = comunidad.Junta;

    if (Junta.Estado) {
      this.setState({ label_abrir: "ABIERTA", abrir: true });
      this.initialiseJitsi();
    } else {
      this.setState({ label_abrir: "CERRADA", abrir: false });
    }

    this.setState({ nombre: Junta.Titulo, descripcion: comunidad.Descripcion });

    this.setState({ tenemosLista: true });
  }

  onSubmit = (event) => {
    this.props.firebase
      .comu(this.state.comunidad.uid + "/Junta")
      .update({
        Titulo: this.state.nombre,
        Estado: this.state.abrir,
      })
      .then(() => {
        this.setState({ snack: true, error2: "La junta " + this.state.nombre + " ha sido actualizada correctamente" });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChange2 = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
    if (event.target.checked) {
      this.setState({ label_abrir: "ABIERTA" });
      this.setState({ entroPrimeravez: true });
      //  this.initialiseJitsi();
    } else {
      this.setState({ label_abrir: "CERRADA" });
      if (this.state.dentro) {
        const _jitsi = this.state.jitsi;
        const participantes = _jitsi.getParticipantsInfo();
        participantes.forEach((parti) => {
          _jitsi.executeCommand("kickParticipant", parti.participantId);
        });
        _jitsi.dispose();
      }
      this.cerrrarVideoCall();
    }
  };

  añadir() {
    this.setState({
      añadir: false,
      mostrarTabla: false,
      identificador: "",
      fecha_publicacion: "",
      publicar: false,
      modificar: false,
      tenemosLista: true,
      nombre: "",
      descripcion: "",
      comunidad: "",
      abrir: false,
      tipo: "añadir",
      sis: "",
      nos: "",
      abstenciones: "",
      total: "",
    });
  }
  cancelar() {
    this.setState({ añadir: true, mostrarTabla: true, tenemosLista: false, modificar: true });
    this.borrarFiltros();
  }

  render() {
    const {
      mostrarTabla,
      label_abrir,
      comus,
      nombre,
      error,
      vertical,
      horizontal,
      snack,
      snack2,
      votacionBorrar,
      tenemosLista,
      usuariosSesion,
      abrir,
      despacho,
      dentro,
      mostrarAyuda,
      Pdfs,
    } = this.state;

    // Proper sorting of comus
    comus.sort((A, B) => {
      const a = Number(A.Id.trim()) || 100000;
      const b = Number(B.Id.trim()) || 100000;
      return a - b;
    });

    const isInvalid = nombre === "";

    return (
      <Grid>
        <Cabecera />
        <hr />

        {/* Help Button */}
        <Grid style={{ position: "absolute", right: 20, top: 20, maxWidth: "15%", maxHeight: "15%" }}>
          <Button variant="contained" color="primary" onClick={() => this.setState({ mostrarAyuda: true })}>
            AYUDA (TUTORIALES)
          </Button>
        </Grid>

        {/* Logo Display */}
        <Grid style={{ position: "absolute", left: 20, top: 5, width: "50%", height: "50%" }}>
          <img src={despacho.Logo} alt="" style={{ width: "17%", height: "15%" }} />
        </Grid>

        <BotonesTriger />
        <Grid container spacing={0}>
          <ThemeProvider theme={theme}>
            {/* Snackbar Messages */}
            {[snack, snack2].map((open, idx) => (
              <Snackbar
                key={`${vertical},${horizontal}-${idx}`}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={5000}
                onClose={this.handleClose}
                message={idx === 0 ? this.state.error2 : votacionBorrar}
                action={
                  <div>
                    {idx === 1 && (
                      <Button onClick={this.anularVotacion}>
                        <Fab size="small" color="primary" variant="extended">
                          <DoneOutlineIcon /> CONFIRMAR
                        </Fab>
                      </Button>
                    )}
                    <Button onClick={this.handleClose}>
                      <Fab size="small" color="secondary" variant="extended">
                        <CloseIcon /> CANCELAR
                      </Fab>
                    </Button>
                  </div>
                }
              />
            ))}

            {/* Search Button */}
            <Grid item xs style={{ marginLeft: 20 }}>
              <Button
                onClick={this.buscarComunidades}
                variant="outlined"
                size="large"
                color="primary"
                startIcon={<SearchIcon />}
              >
                LISTADO ACTUAL DE SESIONES
              </Button>

              {/* Loading Backdrop */}
              <Backdrop color="green" open={this.state.open}>
                <CircularProgress color="green" />
              </Backdrop>

              {/* Communities List */}
              {mostrarTabla && (
                <Paper style={{ maxHeight: 600, overflow: "auto", width: 600 }}>
                  <List component="nav" dense aria-label="main mailbox folders">
                    {comus.map((votacion) => (
                      <Grid key={votacion.Id}>
                        <Divider />
                        <ListItem
                          button
                          selected={this.selectedIndex === votacion.Nombre}
                          onClick={(event) => this.handleListItemClick(event, votacion)}
                        >
                          <ListItemText primary={votacion.Nombre} secondary={votacion.Id} />
                        </ListItem>
                      </Grid>
                    ))}
                  </List>
                </Paper>
              )}
            </Grid>

            {/* Form Section */}
            {tenemosLista && (
              <Grid item xs style={{ marginRight: 20, marginTop: 10 }}>
                <Paper style={{ width: 700 }}>
                  <form>
                    <Button
                      onClick={this.onSubmit}
                      fullWidth
                      size="large"
                      color="primary"
                      startIcon={<SaveIcon />}
                      variant="contained"
                      disabled={isInvalid || abrir}
                    >
                      GUARDAR
                    </Button>
                    {error && <p>{error.message}</p>}
                    <Grid container direction="row" justifyContent="space-around" alignItems="center">
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        margin="normal"
                        disabled
                        id="nombre"
                        size="small"
                        label="Comunidad"
                        name="comunidad"
                        value={this.state.identIncial}
                        placeholder="Título de la Junta"
                      />
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        margin="normal"
                        required
                        id="nombre"
                        size="small"
                        label="Titulo de la Junta"
                        name="nombre"
                        value={nombre}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Título de la Junta"
                      />
                    </Grid>

                    <Grid container direction="row" justifyContent="space-around" alignItems="center">
                      <FormGroup>
                        <FormLabel component="legend">Abrir la Conexión</FormLabel>
                        <FormControlLabel
                          control={<Switch checked={abrir} onChange={this.onChange2} name="abrir" color="primary" />}
                          label={label_abrir}
                        />
                      </FormGroup>
                      {abrir && (
                        <Button
                          onClick={() => {
                            this.setState({ dentro: true });
                            this.initialiseJitsi();
                          }}
                          size="large"
                          color="primary"
                          startIcon={<PlayArrowOutlinedIcon />}
                          variant="outlined"
                          disabled={dentro}
                        >
                          ENTRAR
                        </Button>
                      )}
                    </Grid>
                  </form>
                </Paper>
                {this.state.abrir && (
                  <Paper style={{ width: 700, marginTop: 10 }}>
                    <div id="jitsi-container-id" style={{ height: 400, width: "100%" }} />
                    <Backdrop color="green" open={this.state.openSesion}>
                      <CircularProgress color="green" />
                    </Backdrop>
                  </Paper>
                )}
              </Grid>
            )}

            {/* Material Table for Connected Users */}
            {tenemosLista && (
              <Grid item xs style={{ marginRight: 20, marginTop: 10 }}>
                <Paper style={{ maxHeight: 500, overflow: "auto", margin: 20 }}>
                  <MaterialTable
                    options={{
                      exportButton: { csv: true, pdf: false },
                      filtering: true,
                      pageSize: 10,
                      grouping: true,
                      pageSizeOptions: [5, 10, 20],
                      headerStyle: { backgroundColor: "green", color: "white" },
                    }}
                    icons={tableIcons}
                    title={<h3 style={{ color: "green" }}>USUARIOS CONECTADOS: {nombre}</h3>}
                    columns={[
                      { title: "Nombre", field: "Nombre" },
                      { title: "Apellido", field: "Apellido" },
                      { title: "DNI", field: "DNI" },
                      { title: "Correo", field: "Email" },
                      { title: "Última Entrada", field: "Entrada" },
                      { title: "Última Salida", field: "Salida" },
                      { title: "Total Duración (minutos)", field: "Tiempo" },
                      { title: "Número de conexiones", field: "Conexiones" },
                      { title: "Número", field: "Numero" },
                      { title: "Portal", field: "Portal" },
                      { title: "Piso", field: "Piso" },
                      { title: "Letra", field: "Letra" },
                    ]}
                    data={usuariosSesion}
                  />
                </Paper>
              </Grid>
            )}

            {/* Help Modal */}
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              open={mostrarAyuda}
              onClose={() => this.setState({ mostrarAyuda: false })}
            >
              {mostrarAyuda && <ModalTutoriales Pdfs={Pdfs} />}
            </Modal>
          </ThemeProvider>
        </Grid>

        <Box mt={40}>
          <Copyright />
        </Box>
      </Grid>
    );
  }
}

const condition = (authUser) => !!authUser;
export default withFirebase(withAuthorization(condition)(Sesiones));
